import React, { useState, useContext, useEffect } from 'react'
import '../../styles/Admin/profile.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faUser, faCopy } from '@fortawesome/free-solid-svg-icons'
import { states } from '../../data/userstates'
import { ShareContext } from '../../context/ShareContext';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as Constants from '../../constants';
import { Link } from 'react-router-dom'
import LoadingImg from '../../assets/loading.gif';
import ApiAlert from '../../components/ApiAlert'
import Loading from '../../components/Loading'
import Expired from '../../components/Expired'

function SpecialPlace() {

  const { airportList, isSidebarClosed, adminToken } = useContext(ShareContext);
  const [passwordOrEdit, setPasswordOrEdit] = useState('')
  const [showPop, setShowPop] = useState(false)

  const [html, setHtml] =useState('');
  const [chtml, setcHtml] =useState('');
  const [images, setImages] =useState([]);
  const [alt, setAlt] =useState('');
  const [loading, setLoading]= useState(false);
  const [parkinglots, setParkinglots]=useState([]);
  const [selectedLots, setSelectedLots] =useState([]);
  const [view, setView] =useState('list');
  const [specialplaces, setSpecialplaces] = useState([]);
  const [specialplace, setSpecialplace] = useState({});
  const [isExpired, setIsExpired] = useState(false);
  const [alert, setAlert]=useState({ open:false});
  const [icopy, setICopy] =useState(-1);
  const pattern= /\[.+?\]/g
  const imgpath= `${Constants.baseUrl}/resources/cms/specialplaces/`;


  // console.log(adminUserDetails);
  useEffect(() => {
    LoadImages();
    loadLots();
    LoadSpecialPlaces();
  }, [])
  





  //ReactQuill part******************
//Text direction
Quill.register(Quill.import("attributors/style/direction"), true);
//Alignment
Quill.register(Quill.import("attributors/style/align"), true);

// Don't forget corresponding css
const Size = Quill.import("attributors/style/size");
Size.whitelist = ["0.75em", "1em", "1.5em", "2.5em"];
Quill.register(Size, true);

//Text indent
const Parchment = Quill.import("parchment");
class IndentAttributor extends Parchment.Attributor.Style {
  add(node, value) {
    if (value === 0) {
      this.remove(node);
      return true;
    } else {
      return super.add(node, `${value}em`);
    }
  }
}

let IndentStyle = new IndentAttributor("indent", "text-indent", {
  scope: Parchment.Scope.BLOCK,
  whitelist: ["1em", "2em", "3em", "4em", "5em", "6em", "7em", "8em", "9em"]
});

Quill.register(IndentStyle, true);

const modules = {
  // https://github.com/quilljs/quill/issues/2905#issuecomment-683128521
  clipboard: {
    matchVisual: false
  },
  toolbar: [
    [
      { size: ["0.75em", "1em", "1.5em", "2.5em"] },
      "bold",
      "italic",
      "underline",
      { color: [] },
      { background: [] },
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { align: [] },
      "link"
    ]
  ]
};
  //end ***********

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedFile, setActiveFile] = useState();
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setActiveFile(file);
    if (file) {
      const reader = new FileReader();

      reader.onload = () => {
        setSelectedImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  } 
 
  const onSaveImage= async(e)=>{
    e.preventDefault();
    console.log('imgPrev data');
    console.log(selectedImage);
 
  
        setLoading(true);    
    //setImgPrev({...imgPrev,open:true,data:e.target.result,type:inst, title:"Upload "+inst, });
        var ext=selectedFile.name.split('.')[1];
        var b64=await Constants.toBase64(selectedFile);
        var base64=b64.split(',')[1]
        var rdata={path:base64 ,type:'place',ext, alt};
        // Sending the FormData using fetch
        fetch(`${Constants.apiUrl}/parkingadmin/contentimage`, {
        method: 'POST',
        body: JSON.stringify(rdata),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': '',
        },
        })
        .then(response => response.json())
        .then(data => {
            setLoading(false); 
            if(data.code==0){
                //saved already
               
              setImages([...images,data.di])
              setAlt('');
              setSelectedImage(null);
              setActiveFile(null);
              
            }
        })
        .catch(error => {
            console.error('Error:', error)
            setLoading(false); 
        });
}
 
const LoadImages = () => {


  fetch(`${Constants.apiUrl}/parkingadmin/contentimages`,{
      method: 'GET',
      headers: {
          "Content-Type": "application/json",
          "Authorization": adminToken
      },
  })
  .then((resp) => resp.json())
  .then((data) => {
     setImages(data);
  }).catch((error) => {
      console.log(error)
      setLoading(false)
     console.log('error loadlotsize');
  })


  }

  const LoadSpecialPlaces = () => {


    fetch(`${Constants.apiUrl}/parkingadmin/specialplaces`,{
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": adminToken
        },
    })
    .then((resp) => resp.json())
    .then((data) => {
       setSpecialplaces(data);
    }).catch((error) => {
        console.log(error)
        setLoading(false)
       console.log('error specialplaces');
    })
  
  
    }

  const onSelectLot=( p)=>{
    //console.log(e.target.value);
    console.log(p);
    console.log('above is p');
    console.log(selectedLots);
 
    var lots=[...selectedLots];
    
    var l=lots?.includes(p.parkinglotid)
    console.log(l);
 //return;
    
    if(l){
     
      var lots=[...selectedLots];
      var nlots=lots.filter((item)=> item!=p.parkinglotid);  //lots?.remove(p.parkinglotid);
     
      setSelectedLots(nlots);

    }else{
      lots.push(p.parkinglotid);
      setSelectedLots(lots);
    }
  }

  const onRemoveParkinglot=(p)=>{

  setLoading(true)
    fetch(`${Constants.apiUrl}/parkingadmin/user/parkinglot/${p.parkinglotid}/remove`, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json',
        'Authorization': adminToken,
      },
      body:""
    })
    .then((resp) => resp.json())
    .then((usersData) =>  {
     //remove the item and update the list
     var lots=selectedLots;
    var nlots=lots.filter((item)=> item!=p.parkinglotid);  //lots?.remove(p.parkinglotid);
    console.log('what is left after removal');
    setSelectedLots({lots});
      setLoading(false)
    })
    .catch((error) => {
      console.log(error);
      setLoading(false)
    })
  }
 
  const loadLots=()=>{
    fetch(`${Constants.apiUrl}/reservation/cms/parkinglots`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        }
      })
      .then((resp) => resp.json())
      .then((data) =>  {
       
        setParkinglots(data);
        
      })
      .catch((error) => {
        console.log(error);
        
      })
    }

    const onSaveSpecialPlace= async(e)=>{
      e.preventDefault();
      console.log('imgPrev data');
      console.log(selectedImage);
   var parkings=selectedLots.join(',');
    
          setLoading(true);    
      //setImgPrev({...imgPrev,open:true,data:e.target.result,type:inst, title:"Upload "+inst, });

         var rdata={...specialplace, note:Constants.b64encode(chtml), parkinglotIds:parkings};
          // Sending the FormData using fetch
          fetch(`${Constants.apiUrl}/parkingadmin/specialplace`, {
          method: 'POST',
          body: JSON.stringify(rdata),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': '',
          },
          })
          .then(response => response.json())
          .then(data => {
              setLoading(false); 
              if(data.code==0){
                  //saved already
                 
                setSpecialplaces([...specialplaces,data.di])
                setAlt('');
                setSelectedImage(null);
                setActiveFile(null);
                setView('list');
              }
          })
          .catch(error => {
              console.error('Error:', error)
              setLoading(false); 
          });
  }  

  const editplace = (e) => {
    setSpecialplace({...specialplace, [e.target.name]: e.target.value})
}

const ClearHtml=()=>{

  var ttag= html.match(pattern);
        if (ttag!=null ){
          const tags = Array.from(html.match(pattern))
          console.log('all availble tags')
          console.log(tags);
          var ccht=html;
          for (let index = 0; index < tags.length; index++) {
              const did = tags[index];
              //check the placeholder
              var allt=did.slice(1,-1).split('|');//to remove first and last letter. before the split
              var img=imgpath+ allt[0]; 
              var style=allt.length>1? `'width:${allt[1]}px;height:${allt[2]}px'`:`'width:${400}px' `

              var rp=`<img src='${img}' style=${style} />`;
              ccht = Constants.ReplaceAll(ccht,did,rp) 
              
              
          }
          setcHtml(ccht);
        }
}

const onModifyPlace =(pl)=>{
  setView('edit'); 
  setSpecialplace(pl);
  setHtml(Constants.b64decode(pl.note))
  setcHtml(Constants.b64decode(pl.note));
  if(pl.parkinglotIds==null){
    setSelectedLots([]);
  }else{
    var pp=pl.parkinglotIds.split(',');
    setSelectedLots(pp);
  }
}

const onAlertOk=()=>{
  setAlert({...ApiAlert, open:false})
}
const onTapCopy =(index)=>{
  const im=images[index];

  // if (window.isSecureContext && navigator.clipboard) {
    navigator.clipboard
    .writeText(`[${im.path}|300|400]`)
    .then(() => {
     // setBttnText("COPIED");
      setICopy(index);
      setTimeout(function () {
        //setBttnText("COPY CODE");
        setICopy(-1);
      }, 3000);
    })
    .catch((err) => {

      console.log(err.message);
    });
 
  

}

useEffect(() => {
  ClearHtml();
}, [html])

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
          {view=='list' && <div>
              <div>
                <h3>List of Special Places [{specialplaces.length}]</h3>
                <div style={{margin:16}}>
                  <button className='buttons' onClick={()=> {setView('new'); setSpecialplace({});}}>New Place</button>
                  </div>
                
              </div>
              <div className="table-wrapper">
                      {specialplaces?.length>0 && 
                      <div>
                         {
                              specialplaces?.map((place, id) => { return ( 
                                <div key={id} style={{display:'flex'}}>
                                  <div>
                                  <img src={`${Constants.baseUrl}/resources/home/${place.imgPath}`} alt="Benefits of New Orleans Parking" loading='lazy' style={{width:300}}/> 
                                  </div>
                                  <div style={{flex:1, padding:16}}>
                                      <h2>{place.title}</h2>
                                      <p>{place.subTitle}</p>
                                      <p>SEO Meta: <i>{place.meta}</i> </p>
                                      <Link to={`/places/${place.availableLink==null?place.recordid:place.availableLink}`}>Permanent Link: {place.availableLink}</Link>
                                      <button className='buttons' onClick={()=> onModifyPlace(place)}>Modify</button>
                                  </div>
                                </div> 
                              ) })
                          }
                        
                        
                        </div> }  
                    </div>

            </div>}  
         {view=='preview' && <div style={{margin:24}}>
              <div>
                <h3>Preview</h3>
                <div style={{textAlign:'right'}}>
                  <button className='buttons' onClick={()=> setView('edit')}> Edit mode</button>
                </div>
              </div>
              <div dangerouslySetInnerHTML={{__html: chtml}}></div>

            </div>}   
            {(view=='edit' || view=='new') && <div className="profile">
                <h1>Special Places</h1>
                <div style={{textAlign:'right'}}>
                  <button className='buttons' onClick={()=> setView('list')}> Back to List</button>
                </div>
                <div className="body">
                  <div className="header">
                    <div className="name">
                      <FontAwesomeIcon icon={faUser} />
                      <h2> Special Places</h2>
                    </div>
                   
                  </div>
               <div style={{display:'flex', flexDirection:'row'}}>
                  <div style={{flex:2}}>
                    <form onSubmit={onSaveSpecialPlace}>
                      <div>
                      <div className='inputs'>
                      <div className="input">
                                    <label htmlFor="holidayRateStartDate">Title</label>
                                    <input type="text"  name='title' value={specialplace.title} onChange={editplace} required />
                                </div>  
                      </div>
                      <div className="input">
                                    <label htmlFor="holidayRateStartDate">Sub Title</label>
                                    <input type="text"  name='subTitle' value={specialplace.subTitle} onChange={editplace}  required />
                                </div>  
                                <div className="input">
                                    <label htmlFor="holidayRateStartDate">Meta (SEO Meta Description)</label>
                                    <input type="text"  name='meta' value={specialplace.meta} onChange={editplace}  required />
                                </div> 
                                 
                      <div className='inputs'>
                      <div className="input">
                                    <label htmlFor="holidayRateStartDate">Permanent Link</label>
                                    <input type="text"  name='availableLink' value={specialplace.availableLink} onChange={editplace}  required />
                                </div> 
                      <div className="input">
                                    <label htmlFor="holidayRateStartDate">Image</label>
                                    <input type="text"  name='imgPath' value={specialplace.imgPath} onChange={editplace}  required />
                                </div>  
                      <div className="input">
                                    <label htmlFor="holidayRateStartDate">Rank (0 for Feature)</label>
                                    <input type="text"  name='rank' value={specialplace.rank} onChange={editplace}  required />
                                </div> 
                      </div>
                      <div className='inputs'>
                      <div className="input">
                                    <label htmlFor="longitude">Longitude (GEO Location)</label>
                                    <input type="text"  name='longitude' value={specialplace.longitude} onChange={editplace}  required />
                                </div> 
                      <div className="input">
                                    <label htmlFor="latitude">Latitude (Geo Location)</label>
                                    <input type="text"  name='latitude' value={specialplace.latitude} onChange={editplace}  required />
                                </div>  
                     
                      </div>
                    </div>
                    <div style={{minHeight:300}}>
                    <div style={{textAlign:'right'}}>
                  <button className='buttons' onClick={()=> setView('preview')}> Preview</button>
                </div>
                      <div className="quill-box" >
                        
                        {/* <ReactQuill name='' id='' placeholder='Type here...' className='quill'  modules={modules}
                            formats={formats}
                            value={editorHtml}
                            onChange={handleChange} theme="snow" /> */}
                            <ReactQuill style={{minHeight:300}}
                                  value={html} 
                                onChange={(e)=>{setHtml(e); console.log(e)}}
                                modules={modules}
                                /*  style={styles} */
                                bounds={`[data-text-editor="form-editor"]`}
                            />
                    </div>
                    </div>
                    
                    
                    

                    <div>
                  <label>Near by Parking Lot</label>
                  <div style={{display:'flex', flexDirection:'row', flexWrap:'wrap'}}>
                  {
                     parkinglots?.length>0 &&    parkinglots.map((p, id) => {
                          return (
                            <div key={id} value={ p.parkinglotid}   className='multilist' style={{flex:1}} ><input type='checkbox' checked={selectedLots?.includes(p.parkinglotid)} onClick={()=>onSelectLot( p)}></input> {p.parkinglotname}</div>
                          )
                        })
                      }
                  </div>
                </div>
                    <div className="btns">
                                        <button className='savebtn' type='submit'>Save Special Place Details</button>
                                    </div>
                        </form>             
                  </div>
                <div style={{flex:1, padding:16}}>
                          <form onSubmit={onSaveImage}>
                                <div className="input">
                                    <label htmlFor="holidayRateStartDate">Image</label>
                                    <input
                                      type="file"
                                      id="upload-input"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                  />
                                </div>
                                <div className="input">
                                    <label htmlFor="holidayRateStartDate">Alt</label>
                                    <input type="text"  name='alttext' value={alt} onChange={(e)=>setAlt(e.target.value)} required />
                                </div>  
                                {loading && <img src={LoadingImg} height={50} alt='Sponsor Logo' />}    
                                <div className="btns">
                                        <button className='savebtn' type='submit'>Save Image</button>
                                    </div>

                          </form>

                          <div>
                          {images?.map((img, id) => { return ( 
                                  <div className="place-copy" key={id} >
                                    <img src={`${Constants.baseUrl}/resources/cms/specialplaces/${img.path}`} alt={img.alt} style={{width:100}} />
                                   <div><span>[{img.path}|300|400]</span></div> 
                                   <div><span>{img.alt}</span></div> 
                                   <div className="tap-copy" onClick={()=> onTapCopy(id)}>
                                    <p>{icopy==id?'COPIED':'Tap to Copy'} </p>
                                    <FontAwesomeIcon icon={faCopy} />
                                </div>
                                  </div>
                                  
                                ) })}
                          </div>
                </div>
               
               </div>
                </div>
                
              
                
            </div>}
           
        </div>
        {alert.open && <div className={ "congrats show" }>
                            <ApiAlert {...alert} onOk={onAlertOk}/>
                        </div> }
            {loading &&  <div className={ "congrats show" }>
                            <Loading />
                        </div>}  
            { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}  
    </div>
  )
}

export default SpecialPlace