import React, { useContext, useState, useRef,useEffect } from 'react';
import '../../styles/Admin/reports.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'

import { Link } from 'react-router-dom';
import { ShareContext } from '../../context/ShareContext';
import { useNavigate } from 'react-router-dom';
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from 'moment';
import Loading from '../../components/Loading'
import Expired from '../../components/Expired';

const years = Array.from({length: new Date().getFullYear() - 2000}, (_, idx) => new Date().getFullYear() - idx);

function Reports() {

    const { adminToken,isSidebarClosed, setAdminReport, setReportForm } = useContext(ShareContext);
    const formData = {
        reportType: 'Summary',
        startDate: '',
        endDate: '',
        month:'',
        year: '2024',
        reportDate: '',
        dataFormat: 'web',
        customername: '',
        transactionID: '',
        reservationstatus: 'All',
        dateType:2,
        agency: 'All',
        parkingLot: 'All',
        airport: 'All', 
        reservationdatetype:1

    }

    const [form, setForm] = useState(formData);
    const [isLoading, setIsLoading]= useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [agencies, setAgencies]=useState([]);
    const [parkinglots, setParkinglots]=useState([]);

    const formChange = (e) => {
        setForm({...form, [e.target.name]: e.target.value})
    }

    const navigate = useNavigate()

    useEffect(() => {
        onAgencies();
        onParkinglots();
         }, [])
    const onReport = (reportname) => {
        //setLoadReport(true)
        
        var dateparam="";
        var navpath="";

        if( form.dateType==1){
                if(!Constants.isDateValid(form.startDate))
                {
                    alert('Select the date for the report');
                    return ;
                }
            dateparam=moment(form.startDate).format("YYYY-MM-DD");
        }else if( form.dateType==2){
            if(form.month=='' || form.year=='')
                {
                    alert('Select the month and year for the report');
                    return ;
                }
            dateparam=form.month+","+form.year;
        }else if( form.dateType==3){
            if(form.year=='')
                {
                    alert('Select the year for the report');
                    return ;
                }
            dateparam=form.year;
        }else if( form.dateType==4){
            if(!Constants.isDateValid(form.startDate) || !Constants.isDateValid(form.endDate))
                {
                    alert('Select the date range for the report');
                    return ;
                }
            dateparam=moment(form.startDate).format("YYYY-MM-DD") +","+moment(form.endDate).format("YYYY-MM-DD");
        }

        var rdata={...form,dateparam,transactionid:  form.transactionID==''?"All":form.transactionID,customername:  form.customername==''?"All":form.customername};
        setIsLoading(true);
        if(reportname=='internal'){
            rdata={...rdata,rt:'r1'};
            reportname='reservation';
            navpath="/admin/reports/reservations"; 
            
        }
        else if(reportname=='external'){
            rdata={...rdata,rt:'r2'};
            reportname='reservation';
            navpath="/admin/reports/reservations";
            
        }
        else if(reportname=='hotel'){
            rdata={...rdata,rt:'rhotel'};
            reportname='reservation';
            
            navpath="/admin/reports/reservations";
        }else if(reportname=='refund'){
            rdata={...rdata, rt:''};
            reportname='refund';
            navpath="/admin/reports/reservation-refund-report";
        }
        else if(reportname=='inventory'){
            rdata={...rdata, rt:''};
            reportname='inventory';
            navpath="/admin/reports/inventory-report";
        }
        else if(reportname=='arrivaldeparture'){
            rdata={...rdata, rt:''};
            reportname='arrivaldeparture';
            navpath="/admin/reports/arrival-departure-report";
        }else if(reportname=='othertaxes'){
            rdata={...rdata, rt:''};
            reportname='othertaxes';
            navpath="/admin/reports/other-taxes-report";
        }else if(reportname=='commission'){
            rdata={...rdata, rt:''};
            reportname='commission';
            navpath="/admin/reports/commission-report";
        }

        setReportForm(rdata);
        const url= `${Constants.apiUrl}/parkingadmin/reports/${reportname}`;
    console.log(JSON.stringify(rdata));
          fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': adminToken
            },
            body: JSON.stringify(rdata)
          })
          .then((resp) => resp.json())
          .then((data) => {
            //setLoadReport(false)
            setIsLoading(false);
            console.log(data);
            //setAdminReport(data)
            if (data.code === 0) {
                
                if(form.dataFormat=="excel"){
                    //file path
                    window.location.href=`${Constants.excelPath}${data.filename}`;
                  //console.log(`${Constants.excelPath}${data.filename}`);
                }else{
                    setAdminReport(data);
                    navigate(navpath);
                }
                
            }else if (data.code==-10){
              setIsExpired(true);
          }else{
            console.log(data.status);
              alert(data.status)
          }
          }).catch((error) => {
            setIsLoading(false);
            console.log(error);
          })
    
        // console.log(createData);
    
      }
    
      const onAgencies=()=>{
        
        fetch(`${Constants.apiUrl}/parkingadmin/agencies`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((data) =>  {
            
            // console.log(jresp);
            setAgencies(data.agencies)
          })
          .catch((error) => {
            
            console.log(error);
          })
      }

      const onParkinglots=()=>{
        
        fetch(`${Constants.apiUrl}/parkingadmin/parkinglots`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
              'Authorization': adminToken,
            },
          
          })
          .then((resp) => resp.json())
          .then((data) =>  {
            
            if(data.code==0){
                setParkinglots(data.lots)
            }else if (data.code==-10){
              setIsExpired(true);
          }
            // console.log(jresp);
            
          })
          .catch((error) => {
            
            console.log(error);
          })
      }

  return (
    <div className='admin-body'>
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
            <div className="reports">
                <div className="head">
                    <div className="left">
                        <h1>Reports</h1>
                        <p>All Reports</p>
                    </div>
                    <p className="note">All reports are in A4 paper size <br />Shortcut Key: Ctrl+P</p>
                </div>
                <div className="body-wrapper">
                    <div className="head-block">
                        <div className="left-head">
                            <h3>Reports</h3>
                        </div>
                    </div>
                    {
                    isLoading && <div className={  'congrats show'}>
                        <Loading message={'Loading Report'} />
                    </div>
                
                }
                 { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}

                    <div className="body">                      
                        <form method="post">
                            <div className="date-param">
                                <h3>Date Parameter</h3>
                                <div className="input">
                                    <label htmlFor="reportType">Report Type</label>
                                    <select name="reportType" id="reportType" onChange={formChange} value={form.reportType}>
                                        <option value="details">Detailed Report</option>
                                        <option value="Summary">Summary Report</option>
                                    </select>
                                </div>
                                <div className="input">
                                    <label htmlFor="reportDate">Select Report Date</label>
                                    <select name="dateType" id="dateType" value={form.dateType} onChange={formChange}>
                                        <option value="" disabled selected>Select Date Parameter</option>
                                        <option value="4">Range (From - To)</option>
                                        <option value="1">Date</option>
                                        <option value="2">Month</option>
                                        <option value="3">Year</option>
                                    </select>
                                </div>
                               {(form.dateType==1 || form.dateType==4) && <div className="input">
                                    <label htmlFor="startDate">Start Date</label>
                                    <input type="date" name='startDate' id='startDate' onChange={formChange} value={form.startDate}/>
                                </div>} 

                                {(form.dateType==4) && <div className="input">
                                    <label htmlFor="endDate">End Date</label>
                                    <input type="date" name='endDate' id='endDate' onChange={formChange} value={form.endDate}/>
                                </div>} 

                               {(form.dateType==2 ) && <div className="input">
                                    <label htmlFor="reportMonth">Month</label>
                                    <select name="month" id="reportMonth" value={form.month} onChange={formChange}>
                                        <option value="" disabled selected></option>
                                        <option value="1">January</option>
                                        <option value="2">February</option>
                                        <option value="3">March</option>
                                        <option value="4">April</option>
                                        <option value="5">May</option>
                                        <option value="6">June</option>
                                        <option value="7">July</option>
                                        <option value="8">August</option>
                                        <option value="9">September</option>
                                        <option value="10">October</option>
                                        <option value="11">November</option>
                                        <option value="12">December</option>
                                    </select>
                                </div>} 
                                {(form.dateType==2 || form.dateType==3) && <div className="input">
                                    <label htmlFor="reportYear">Year</label>
                                    <select name='year' id='reportYear' value={form.year} onChange={formChange}>
                                        {
                                            years.map((year, id) => <option key={id} value={year}>{year}</option>)
                                        }
                                    </select>
                                </div>} 
                               
                                <div className="input">
                                    <label htmlFor="dataFormat">Data Format</label>
                                    <select name="dataFormat" id="dataFormat" value={form.dataFormat} onChange={formChange}>
                                        <option value="web">Web</option>
                                        <option value="excel">Excel</option>
                                    </select>
                                </div>
                            </div>
                            <div className="reservation-param">
                                <h3>Reservation Parameter</h3>
                                <div className="input">
                                    <label htmlFor="customerName">Customer Name</label>
                                    <input type="text" name='customername' id='customername' value={form.customername} onChange={formChange}/>
                                </div>
                                <div className="input">
                                    <label htmlFor="transactionId">Transaction ID</label>
                                    <input type="text" name='transactionID' id='transactionId' value={form.transactionID} onChange={formChange}/>
                                </div>
                                <div className="input">
                                    <label htmlFor="reservationStatus">Reservation Status</label>
                                    <select name="reservationstatus" id="reservationstatus" value={form.reservationstatus} onChange={formChange}>
                                        <option value="All">All</option>
                                        <option value="Open">Open</option>
                                        <option value="Closed">Closed</option>
                                        <option value="Canceled">Canceled</option>
                                        <option value="Open and Closed">Open and Closed</option>
                                    </select>
                                </div>
                                <div className="input">
                                    <label htmlFor="reservationDateType">Select Date Type</label>
                                    <select name="reservationdatetype" id="reservationdatetype" value={form.reservationdatetype} onChange={formChange}>
                                        <option value="1">Reservation Date</option>
                                        <option value="2">Arrival Date</option>
                                        <option value="3">Departure Date</option>
                                    </select>
                                </div>
                                <div className="input">
                                    <label htmlFor="airport">Reservation Airport</label>
                                    <select name="airport" id="airport" value={form.airport} onChange={formChange}>
                                        <option value="All">All</option>
                                       
                                    </select>
                                </div>
                                <div className="input">
                                    <label htmlFor="parkingLot">Parking Lot</label>
                                    <select name="parkingLot" id="parkingLot" value={form.parkingLot} onChange={formChange}>
                                        <option value="All">All Parking Lot</option>
                                        {
                                            parkinglots?.map((lot, idx) => <option key={idx} value={lot.parkinglotid}>{lot.parkinglotname}</option>)
                                        }
                                    </select>
                                </div>
                                <div className="input">
                                    <label htmlFor="agency">Reservation Agency</label>
                                    <select name="agency" id="agency" value={form.agency} onChange={formChange}>
                                    <option value="All">All</option>
                                    { agencies.map((item, idx) => {return (   
                                    <option value={item.agencyid} key={idx}>{item.agencyname}</option>
                                    )}) }
                                       
                                    </select>
                                </div>
                            </div>
                            <div className="report-buttons">
                                <h3>Which Reports are you opening?</h3>
                                <p>Please fill in all the date parameters form before clicking on a type of report</p>
                                <div className="buttons">
                                    <button type='button' onClick={()=>onReport("ticket")}>Submitted Tickets</button>
                                    
                                    <button type='button' onClick={()=>onReport("internal")}>Reservations(Internal Report)</button>
                                    
                                    <button type='button' onClick={()=>onReport("external")}>Reservations(External Report)</button>
                                    

                                        <button type='button' onClick={()=>onReport("hotel")}>Hotel Report</button>
                                  
                                    <button type='button' onClick={()=>onReport("arrivaldeparture")}>Arrival / Departure Report</button>
                                    
                                    <button type='button' onClick={()=>onReport("othertaxes")}>Other Taxes / Fees Report</button>

                                   <button type='button' onClick={()=>onReport("commission")}>Travel Agency Commission Report</button>
                                  <button type='button' onClick={()=>onReport("refund")} >Reservation Refund Report</button>
                                   

                                    <button type='button'  onClick={()=>onReport("inventory")}>Current Inventory Report</button>
                                    
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Reports