import React, { useContext, useState, useEffect} from 'react'
import { ShareContext } from '../../context/ShareContext';
import '../../styles/User/airportform.css'
import { timeArray } from '../../data/timeArray';
import { faSearch, faCar, faHotel, faBed } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from "moment";

function AirportForm( props) {
    const { formData, setFormData, setIsFormSubmitted,  setSearchData, setReservationId, hrates, setHrate} = useContext(ShareContext)
    const [loading, setLoading]=useState(false);
    const {reservationId} =props;

    console.log('received props');
    console.log(props);
    const [hourlyrates, setHourlyrates]=useState(null);
    const fetchurl=reservationId==null || reservationId==0?  `${Constants.apiUrl}/reservation/search`: `${Constants.apiUrl}/reservation/${reservationId}/search`;
    const navigateTo=reservationId==null || reservationId==0?'/reservation/search': `/reservation/search/${reservationId}`;
    // console.log('fetch search url '+fetchurl);
    const currentDate =moment().add(-1,'d').format('YYYY-MM-DD');// new Date().toISOString().split('T')[0];

    const navigate = useNavigate();
    const isWidget= props.isWidget==null?false:props.isWidget;
    const showTab= props.showTab==null?true:props.showTab;

    useEffect(() => {
      setHourlyrates(props.hourlyrates);
      console.log('in use effect, setting hourlyrates');
      console.log(props.hourlyrates);
      }, [props.hourlyrates])

    const handleChange = (e) => {
        console.log(`targetname: ${e.target.name}: ${e.target.value} hourlyrateid: ${formData.hourlyRateId}`)
        console.log('rates available')
        console.log(hourlyrates);
        if(e.target.name=='hourlyRateId'){
            //get the hour rate selected, then get the time, use it to calculate the new time
            //******************** */
   
                const selectedTimeOption = hourlyrates.find(option => option.id == e.target.value);
                console.log(selectedTimeOption);
                const duration = moment.duration(selectedTimeOption?.startHr);

            
                const startMoment = moment(formData.startDate, "YYYY-MM-DD").add(moment.duration(formData.startTime)) ;
                //startMoment.add(duration)
                console.log(startMoment.format("YYYY-MM-DD hh:mm"));
                const startdatetime=startMoment.format("YYYY-MM-DD HH:mm");
               
                //const endd=endMoment.format();
                const endMoment = startMoment.add(duration); //.add(startd);
                const enddatetime=endMoment.format("YYYY-MM-DD HH:mm");
                var endTime = endMoment.format("HH:mm");
                var endDate =endMoment.format("YYYY-MM-DD");
                console.log(formData);
                console.log(`duration: ${duration}, startmoment: ${startdatetime}, endmoment ${enddatetime} however the real timer ${formData.startdatetime}- ${formData.enddatetime}`);
               //var vv=
            /********** */
            var fform={ ...formData, [e.target.name]: e.target.value,endTime: endTime,startdatetime:startdatetime,enddatetime:enddatetime,endDate:endDate}
            console.log(fform);
            setFormData(fform);
            
        }else if(formData.rateType=='hourly' && e.target.name=='startDate' && hourlyrates==null){
            setFormData({ ...formData, [e.target.name]: e.target.value, endDate:e.target.value}); 
        }else if(hourlyrates!=null && e.target.name=='startDate'){
            setFormData({ ...formData, [e.target.name]: e.target.value, endDate:e.target.value}); 
            //RELOAD WHAT ITS For that field
            LoadHourly(e.target.value);
        }
        else{
           setFormData({ ...formData, [e.target.name]: e.target.value}); 
        }
        
    };

    const LoadHourly=async(date)=>{
        setLoading(true);
     
   var fdata={parkinglotid:props.parkinglotid,mainDate:date};
       
        var searchparam=JSON.stringify(fdata);
        console.log(searchparam);
      
        try {
            const resp = await fetch(`${Constants.apiUrl}/reservation/event/specialrates`, {
              method: 'POST',
              body: JSON.stringify(fdata),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            
            const responseData = await resp.json();
           
             console.log(responseData)
             setHourlyrates(responseData);
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
    }

    const dummySubmit = async(e) => {
        setLoading(true);
        e.preventDefault();
   var fdata={...formData, "airportCode": "MSY"};
        if(formData.rateType=='hourly'){
            fdata.endDate=fdata.startDate;
            setFormData(fdata);
        }
        var searchparam=JSON.stringify(fdata);
        console.log(searchparam);
        localStorage.setItem('searchdata', searchparam);
        try {
            const resp = await fetch(fetchurl, {
              method: 'POST',
              body: JSON.stringify(fdata),
              headers: {
                'Content-Type': 'application/json',
              },
            });
            
            const responseData = await resp.json();
            setSearchData(responseData)
             console.log(responseData)
            if (responseData.code === 0) {
                setSearchData(responseData)
                navigate(navigateTo)
                setIsFormSubmitted(true)
            }else {
              console.log(responseData.status);
            }
            setLoading(false);
          } catch (error) {
            console.error(error);
            setLoading(false);
          }
        
    }

    const onChangeTab=(d)=>{
        
        if(d=='cruise'){
            setFormData({...formData,reservationtype:d, rateOption:'both', rateType:'daily'});
        }else if(d=='hotel'){
            setFormData({...formData,reservationtype:d, rateOption:'daily', rateType:'daily'});
        }else if(d=='downtown'){
            setFormData({...formData,reservationtype:d, rateOption:'hourly',rateType:'hourly'});
        }
    }
    const onChangeRate=(d)=>{
        setFormData({...formData,rateType:d});
    }

  return (
    <div className="search-airport">
        
        {/* <p className='note'>Note: Customers can choose any time they want even if the shuttle service does not run during those times</p> */}
        <form method='post' onSubmit={dummySubmit}>
          {(!isWidget && showTab) && <div className='hometabs'>
                <div className={formData.reservationtype=='cruise'?'hometabactive':'hometab'}  onClick={()=>onChangeTab('cruise')}> <FontAwesomeIcon icon={faCar} /> Cruise Parking</div>
                <div className={formData.reservationtype=='hotel'?'hometabactive':'hometab'} onClick={()=>onChangeTab('hotel')}><FontAwesomeIcon icon={faBed} /> Hotel Parking</div>
                {/* <div className={formData.reservationtype=='both'?'hometabactive':'hometab'} onClick={()=>onChangeTab('both')}><FontAwesomeIcon icon={faCar} />+<FontAwesomeIcon icon={faBed} /> Cruise and Hotel Parking</div> */}
                <div className={formData.reservationtype=='downtown'?'hometabactive':'hometab'} onClick={()=>onChangeTab('downtown')}><FontAwesomeIcon icon={faCar} /> Downtown Parking</div>
            </div>} 
           {formData.rateOption=='both' && <div className='hometabs'>
            <div className={formData.rateType=='daily'?'hometabactive':'hometab'}  onClick={()=>onChangeRate('daily')}> <FontAwesomeIcon icon={faCar} /> Overnight Rate</div>
            <div className={formData.rateType=='hourly'?'hometabactive':'hometab'}  onClick={()=>onChangeRate('hourly')}> <FontAwesomeIcon icon={faCar} /> Hourly Rate</div>
                </div> } 
            <div className="inputs">
           
                <div className='each'>
                    <div className="input">
                        <div className="label">
                            <p>Arrival Date</p>
                        </div>
                      {reservationId==0 && <input type="date" name="startDate" id="arrival-date" value={formData.startDate} onChange={handleChange} min={currentDate} required/>}  
                      {reservationId>0 && <input type="date" name="startDate" id="arrival-date" value={formData.startDate} onChange={handleChange}  required/>}  
                    </div>
                   
                    <div className="input">
                        <div className="label">
                            <p>Arrival Time</p>
                        </div>
                        <select name="startTime" value={formData.startTime}  onChange={handleChange} className="time arrival-time" required>
                        <option disabled>Arrival Time</option>
                            {
                                timeArray.map((time, id) => {
                                    return(
                                        <option key={id} value={time.value}>{time.time}</option>
                                    )
                                })
                            }
                    </select>
                    </div>

                   
                </div> 
                <div className='each'>
                 {formData.rateType=='daily' && <div className="input">
                        <div className="label">
                            <p>Departure Date</p>
                        </div>
                        <input type="date" name="endDate" id="departure-date" value={formData.endDate} onChange={handleChange} required min={formData.startDate}/>
                    </div>}   
                  {(props.isWidget &&  hourlyrates!=null && formData.rateType=='hourly') && 
                  <div className="input">
                        <div className="label">
                            <p>Duration and rate</p>
                        </div>
                        <select name="hourlyRateId"  value={formData.hourlyRateId} className=" departure-time" onChange={handleChange}  required>
                            <option >Available Duration</option>
                            {
                                hourlyrates?.map((time, index) => {
                                    return(
                                        <option key={index} value={time.id}>{Constants.formatTime2(time.startHr)  } -- Rate {Constants.formatCurrency(time.rate)}</option>
                                    )
                                })
                            }
                    </select>
                    </div>}  
                  
                  {!(props.isWidget &&  hourlyrates!=null && formData.rateType=='hourly') && <div className="input">
                        <div className="label">
                            <p>Departure Time</p>
                        </div>
                        <select name="endTime"  value={formData.endTime} className="time departure-time" onChange={handleChange}  required>
                            <option disabled>Departure Time</option>
                            {
                                timeArray.map((time, index) => {
                                    return(
                                        <option key={index} value={time.value}>{time.time}</option>
                                    )
                                })
                            }
                    </select>
                    </div>}
                  
                    
                </div>
            </div>
          {!isWidget && <div style={{flexDirection:'row'}}>
                <button type="submit" style={{ display:'inline'}}> Search <FontAwesomeIcon icon={faSearch} /></button>
          {/*  {reservationId>0 && <button type="button" style={{ display:'inline'}} onClick={()=>setReservationId(0)}> Clear Modification {reservationId} </button>}  */}
            </div> }  
            
            {loading && <img src={LoadingImg} height={50} alt='Sponsor Logo' />}            
    </form>
    </div>
    
  )
}

export default AirportForm