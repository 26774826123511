import React, { useState, useContext, useEffect } from 'react';
import '../../styles/Admin/manageairport.css'
import '../../styles/Admin/newlots.css'
import Sidebar from '../../components/Admin/Sidebar'
import Head from '../../components/Admin/Head'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faRotateRight, faSearch, faDownLeftAndUpRightToCenter, faUpRightAndDownLeftFromCenter} from '@fortawesome/free-solid-svg-icons'
import { ShareContext } from '../../context/ShareContext'
import { states } from '../../data/userstates'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams, useNavigate } from 'react-router-dom'
import Loading from '../../components/Loading'
import LoadingImg from '../../assets/loading.gif';
import * as Constants from '../../constants';
import moment from "moment";
import Expired from '../../components/Expired'
import ApiAlert from '../../components/ApiAlert'

function NewParkinglot() {
    const {parkinglotid}=useParams();
    const navigate = useNavigate();
    const { airportList, isSidebarClosed, adminToken } = useContext(ShareContext);

    // console.log(airportList);
    const [loading, setLoading] = useState(false)
    const [loadMsg, setLoadMsg] = useState('')

    const [view, setView]= useState('list');
    const [expandForm, setExpandForm] = useState(false)
    const [openCreate, setOpenCreate] = useState(false);
    const [editData, setEditData] = useState({});
    const [openedEdit, setOpenedEdit] = useState(false)
    const [isDialogBoxOpen, setIsDialogBoxOpen] = useState(false)
    const [isNew, setIsNew] = useState(false);
    const [isLoading, setIsLoading]= useState(false);
    const [isExpired, setIsExpired] = useState(false);
    const [alert, setAlert]=useState({ open:false});

    const [searchValue, setSearchValue] = useState('');
    const [filterCity, setFilterCity] = useState('')

    const [airportState, setAirportState] = useState(airportList)
    
    const [activelot, setActivelot] =useState({});
    const [atab, setAtab]=useState('lot');
    const [bookeds, setBookeds]=useState([]);
    const [reserves, setReserves]=useState([]);
    const [reserve, setReserve]=useState({});

 
    const [specialrate,setSpecialrate] = useState({id:0});
    const [specialrates, setSpecialrates]=useState([]);
   const [blockPicked, setBlockPicked]  = useState({});
   const [blockoutDates, setBlockoutDates]  =useState([]);
   const [lotsizePicked, setLotsizePicked]= useState({});
   const [lotSizes, setLotSizes]= useState([]);
    const [otherTaxes, setOtherTaxes]  =useState([]);
   const [otherTaxPicked, setOtherTaxPicked]= useState({});
   const [lotImages,setLotImages]= useState([]);
   const [lotImage, setLotImage] =useState({});


   const [hours, setHours]=useState([]);
    const [lotHours, setLotHours]=useState([]);
    const [newHours, setNewHours] = useState([])
   const defaultHours= [
    {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''},
     {id:1, rate:0, time:''}];     

    

    const onAlertOk=()=>{
        setAlert({...ApiAlert, open:false})
    }

   const lotsizeChange = (e) => {
    setLotsizePicked({...lotsizePicked, [e.target.name]: e.target.value})
    }
const edittaxchange = (e) => {
        setOtherTaxPicked({...otherTaxPicked, [e.target.name]: e.target.value})
    }
   const editLotSize=(obj)=>{
    setLotsizePicked(obj);
   }
   /*  const cities = airportState.map(airport => airport.airportcity)
    const newCities = [...new Set(cities)] */

   


 
 


const LoadLot = (id) => {
   
   setLoading(true)
   setLoadMsg('Loading Parking Lot Details')

   fetch(`${Constants.apiUrl}/parkingadmin/parkinglots/${id}`,{
       method: 'GET',
       headers: {
           "Content-Type": "application/json",
           "Authorization": adminToken
       },
   })
   .then((resp) => resp.json())
   .then((data) => {
       // console.log(data);
       setLoading(false)
       console.log(data.pl);
       if(data.code==0){
       
           setActivelot(data.pl);
           setLotHours(data.hourlyrates==null?[]:data.hourlyrates);
           setLotImages(data.lotImages==null?[]:data.lotImages);
       }else if(data.code==-10){
           setIsExpired(true);
       }
       
       
   })
   .catch((error) => {
       console.error(error);
       setLoading(false)
       setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed});
   })
}

const onSaveParkinglot=(e)=>{
    e.preventDefault();
    const url= isNew ?`${Constants.apiUrl}/parkingadmin/parkinglot` : `${Constants.apiUrl}/parkingadmin/parkinglot/${activelot.parkinglotid}`;
    if((activelot.rateType=='both' || activelot.rateType=='hourly') && lotHours.length==0){
        setAlert({open:true, title:'Error in Saving', subtitle:'You have selected the rate type to be hourly and no hourly rate entry. Click the Add Hourly rates button.'});
        return;
    }


var ractive={...activelot,lotImages:[], hourlyRates:lotHours,}
    console.log(JSON.stringify(ractive));
    setLoading(true);
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': adminToken
        },
        body: JSON.stringify(ractive)
    })
    .then((resp) => resp.json())
    .then((data) => {
        setLoading(false);
        console.log(data);
        if(data.code==0){
              setOpenCreate(false); //close the dialog
            setAlert({open:true, title:'Parking Lot', subtitle:'Parking Lot Saved Successfully'});
          
        } 
        else if(data.code==-10){
            setIsExpired(true);
        }
        else{
            setAlert({open:true, title:'Parking Lot Issues', subtitle:data.status});
        }
    })
    .catch((error) => {
        console.log('**************************error *************************')
        console.log(error)
        setLoading(false)
         setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
    })
  }
  const airports = airportList;


useEffect(() => {
    if(parkinglotid==null){
        setView('new')
    }else{
        setView('list');
        LoadLot(parkinglotid);
        LoadLotSize(); LoadSpecialRate();LoadBlockoutDate();LoadOtherTaxes();LoadReserves();
    }
   
  }, [adminToken])

  const onChangeTab=(d)=>{
        setAtab(d);
  }
  const lotDataChange=(e)=>{
    const { name, value } = e.target;
    setActivelot({ ...activelot, [name]: value });
}

const editSpecialRateChange = (e) => {
    setSpecialrate({...specialrate, [e.target.name]: e.target.value})
}
const editblockchange = (e) => {
    setBlockPicked({...blockPicked, [e.target.name]: e.target.value})
}

const editHourChange = (id,e) => {
    var c_hrs=[...hours];
    var l={...c_hrs[id]};
    l={...l, [e.target.name]: e.target.value};
    c_hrs[id]={...l};
    setHours(c_hrs)  ;
}
const editLotHourChange = (id,e) => {
    var c_hrs=[...lotHours];
    var l={...c_hrs[id]};
    l={...l, [e.target.name]: e.target.value};
    c_hrs[id]={...l};
    setLotHours(c_hrs)  ;
}
const changeDate = (dateInp) => {
    const date = new Date(dateInp);

    const formmattedDate = new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);

    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    const finalFormat = `${formmattedDate} ${formattedTime}`;

    return finalFormat;
  };

  const dateOnly = (data) => {
    const date = new Date(data);

    const formmattedDate = date.toISOString().split('T')[0]

    return formmattedDate
  
  } 
  function formatDate(date) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
    return new Date(date).toLocaleDateString(undefined, options);
}
function convertToHumanReadableDate(htmlDateFormat) {
    const dateObject = new Date(htmlDateFormat);
  
    const day = dateObject.getDate();
    const monthIndex = dateObject.getMonth();
    const year = dateObject.getFullYear();
  
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
  
    const monthName = months[monthIndex];
  
    // Add ordinal suffix to the day
    const dayWithSuffix = addOrdinalSuffix(day);
  
    // Construct the human-readable date format
    const humanReadableDate = `${dayWithSuffix} ${monthName}, ${year}`;
  
    return humanReadableDate;
  }
  // Function to get today's date as a string
function getTodayDate() {
    const today = new Date();
    return formatDate(today);
}
const editspecialRate=(obj)=>{
    setView('edit');
    setSpecialrate(obj);
    var hs=obj.hourlyRates==null?[]:obj.hourlyRates;
    setHours(hs);
    //
}
const deleteSpecialRate=(id)=>{
    //item to delete
    //const updatedArray = lots?.filter(obj => obj.parkinglotid !== id)
      
    //setLots(updatedArray)
    //setIsDialogBoxOpen(false)
}
function addOrdinalSuffix(day) {
    if (day >= 11 && day <= 13) {
      return `${day}th`;
    }
  
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  }
const editReserve=(obj)=>{
    setReserve(obj);
}
const editReserveChange = (e) => {
    setSpecialrate({...reserve, [e.target.name]: e.target.value})
}
const editParkinglotImageChange = (e) => {
   
    setLotImage({...lotImage, [e.target.name]: e.target.value});
}
const deleteBlockout=(id)=>{

}
const onBlockout=(e)=>{
    e.preventDefault();
    const url= (blockPicked.recordid==null || blockPicked.recordid==0 ) ?`${Constants.apiUrl}/parkingadmin/blockedoutdate` : `${Constants.apiUrl}/parkingadmin/blockedoutdate/${blockPicked.recordid}`;

    console.log(JSON.stringify(blockPicked));
    setLoading(true);
    var bl={...blockPicked, parkinglotid: activelot.parkinglotid, recordid:blockPicked.recordid}
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': adminToken
        },
        body: JSON.stringify(bl)
    })
    .then((resp) => resp.json())
    .then((data) => {
        setLoading(false);
        console.log(data);
        if(data.code==0){
              setOpenCreate(false); //close the dialog
            setAlert({open:true, title:'Blockout Date', subtitle:'Blockdate Saved Successfully'});
          
        } 
        else if(data.code==-10){
            setIsExpired(true);
        }
        else{
            setAlert({open:true, title:'Blockout Issues', subtitle:data.status});
        }
    })
    .catch((error) => {
        console.log('**************************error *************************')
        console.log(error)
        setLoading(false)
         setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
    })
  }
  const editTax=(obj)=>{
    setOtherTaxPicked(obj);
  }
  const onTax =  async(e) => {

    // console.log(otherTaxPicked);

    const sentData = {
        "lotid": parkinglotid,
        "taxbasis": otherTaxPicked.taxbasis,
        "taxfreedays": otherTaxPicked.taxfreedays,
        "taxid": otherTaxPicked.taxid,
        "taxname": otherTaxPicked.taxname,
        "taxstatus": otherTaxPicked.taxstatus,
        "taxtype": otherTaxPicked.taxtype,
        "taxvalue": otherTaxPicked.taxvalue
    }

    // console.log(sentData);
    e.preventDefault();
    setLoading(true)
    fetch(`${Constants.apiUrl}/api/parkingadmin/othertax/update`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': adminToken
        },
        body: JSON.stringify(sentData)
    }).then((resp) => {
        resp.json()
        console.log(resp);
    })
    .then((data) => {
        setLoading(true);
        console.log(data);
    })
    .catch((error) => {
        console.log(error)
        setLoading(false)
        setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
    })
  }
//
const onSaveSpecialRate =  async(e) => {
    e.preventDefault();

    var reqdata={...specialrate, hourlyRates:hours, parkinglotId:parkinglotid};
    const url=specialrate.id==0?`${Constants.apiUrl}/parkingadmin/specialrate`: `${Constants.apiUrl}/parkingadmin/specialrates/${specialrate.id}`
   console.log(reqdata);
   console.log(JSON.stringify(reqdata));
    setLoading(true)
    fetch( url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': adminToken
        },
        body: JSON.stringify(reqdata)
    }).then((resp) => {
    
        resp.json();
        
        
    })
    .then((data) => {
        setLoading(false);
        if(specialrate.id==0){
           var srs=[...specialrates, {...reqdata,id:data.id, hourlyRates:data.hours}]
        console.log(srs);
        setSpecialrates(srs); //update the list 
        }else{
            //get the idex out and update it, then fix it back
            
        }
        
        setView('list');
        setAlert({open:true, title:'Event Rate', subtitle:'Event Rate Saved Successfully'});
    })
    .catch((error) => {
        console.log(error)
        setLoading(false)
        setAlert({open:true, title:'We encounter an issue', subtitle:Constants.NetworkFailed})
    })
  }
  const LoadLotSize = () => {
    const id=parkinglotid;
    setLoadMsg('Loading Lot Sizes')
    setLoading(true)
    fetch(`${Constants.apiUrl}/parkingadmin/${id}/dailylotsizes`,{
        method: 'GET',
        headers: {
            "Content-Type": "application/json",
            "Authorization": adminToken
        },
    })
    .then((resp) => resp.json())
    .then((data) => {
        setLoading(false)
        // console.log(data);
        setLotSizes(data)
    }).catch((error) => {
        console.log(error)
        setLoading(false)
       console.log('error loadlotsize');
    })

 
    }
  const LoadSpecialRate = () => {
        const id=parkinglotid;
       
        setLoading(true)
        setLoadMsg('Loading Holiday Rates')
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/specialrates`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setSpecialrates(data)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
           console.log('error Loadspecialrate');
        })

      
    }
    const LoadBlockoutDate = () => {
        const id=parkinglotid;
       

        setLoading(true)
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/blockedoutdates`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setBlockoutDates(data.bud)
        setLoading(false)

        }) .catch((error) => {
            console.log(error)
            setLoading(false)
           console.log('error LoadBlockoutDate');
        })
      
    }
    const LoadOtherTaxes = () => {
        const id=parkinglotid;
     

        setLoading(true)
        setLoadMsg('Loading Other Taxes')
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/othertax`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setOtherTaxes(data.bud)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
           console.log('error other takes');
        })

       
    }
    const LoadReserves = () => {
        const id=parkinglotid;
     

        setLoading(true)
        setLoadMsg('Loading Reserve')
        fetch(`${Constants.apiUrl}/parkingadmin/${id}/reserves`,{
            method: 'GET',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            // console.log(data);
            setReserves(data.bud)
            setLoading(false)
        }).catch((error) => {
            console.log(error)
            setLoading(false)
           console.log('error load reserves');
        })

       
    }
    const onRemoveLotImage = (img,index) => {
      var id=img.recordid;
        setLoading(true)
        setLoadMsg('Loading Reserve')
        fetch(`${Constants.apiUrl}/parkingadmin/parkinglotimage/${id}`,{
            method: 'DELETE',
            headers: {
                "Content-Type": "application/json",
                "Authorization": adminToken
            },
        })
        .then((resp) => resp.json())
        .then((data) => {
            setLoading(false);
            
            console.log(activelot.lotImages);
            var lis=[...activelot.lotImages];
            lis.splice(index,1);
            console.log(lis);
           // setLotImages(lis);
            setActivelot({...activelot,lotImages:lis});
        }).catch((error) => {
            setLoading(false)
           console.log('error load Parkinglotimages');
        })

       
    }
    const onRemoveHour=(id)=>{
        console.log(`index in question ${id}`);
        console.log(hours)
        var hrs=[...hours];
        hrs.splice(id,1);
        console.log(hrs)
        setHours(hrs);
    }
    const onRemoveLotHour=(id)=>{
        console.log(`index in question ${id}`);
        console.log(lotHours)
        var hrs=[...lotHours];
        var _id=lotHours[id].id;
        if(_id>0){
            onRemoveHourlyServer(_id);
        }
        
        hrs.splice(id,1);
        console.log(hrs)
        setLotHours(hrs);
    }

    const onRemoveHourlyServer = (id) => {
       
          setLoading(true)
          setLoadMsg('removing hourly rate')
          fetch(`${Constants.apiUrl}/parkingadmin/hourlyrates/${id}`,{
              method: 'DELETE',
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": adminToken
              },
          })
          .then((resp) => resp.json())
          .then((data) => {
              setLoading(false);
             
          }).catch((error) => {
              setLoading(false)
             console.log('error load hourly rate');
          })
  
         
      }

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedFile, setActiveFile] = useState();
    const handleImageChange = (e) => {
      const file = e.target.files[0];
      setActiveFile(file);
      if (file) {
        const reader = new FileReader();
  
        reader.onload = () => {
          setSelectedImage(reader.result);
        };
  
        reader.readAsDataURL(file);
      }
    } 
   
    const onSaveImage= async(e)=>{
      e.preventDefault();
      console.log('imgPrev data');
      console.log(selectedImage);
   
    
          setLoading(true);    
      //setImgPrev({...imgPrev,open:true,data:e.target.result,type:inst, title:"Upload "+inst, });
          var ext=selectedFile.name.split('.')[1];
          var b64=await Constants.toBase64(selectedFile);
          var base64=b64.split(',')[1]
          var rdata={imagename:base64 ,ext, alt:lotImage.alt, parkinglotid};
          // Sending the FormData using fetch
          fetch(`${Constants.apiUrl}/parkingadmin/lotimage`, {
          method: 'POST',
          body: JSON.stringify(rdata),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': '',
          },
          })
          .then(response => response.json())
          .then(data => {
              setLoading(false); 
              if(data.code==0){
                  //saved already
               var imgs= [... activelot.lotImages,data.di]
                setActivelot({...activelot,lotImages:imgs});
                setLotImage({});
                setSelectedImage(null);
                setActiveFile(null);
                setView('list');
              }
          })
          .catch(error => {
              console.error('Error:', error)
              setLoading(false); 
          });
  }
  return (
    <div className="admin-body">
        <Sidebar />
        <div className={ isSidebarClosed ? "right-side full" : "right-side"}>
            <Head />
           <div> 
            <div style={{margin:16}}>
               <h2>{activelot.parkinglotname}</h2> 
               <h3>{activelot.address1}</h3>
            </div>
           <div className='hometabs'>
                <div className={atab=='lot'?'hometabactive':'hometab'}  onClick={()=>onChangeTab('lot')}>  Lot Details</div>
                <div className={atab=='holiday'?'hometabactive':'hometab'} onClick={()=>onChangeTab('holiday')}>  Event Rates</div>
                <div className={atab=='reserved'?'hometabactive':'hometab'} onClick={()=>onChangeTab('reserved')}> Reserved Dates</div>
                <div className={atab=='booked'?'hometabactive':'hometab'} onClick={()=>onChangeTab('booked')}> Booked Date</div>
                <div className={atab=='blockout'?'hometabactive':'hometab'} onClick={()=>onChangeTab('blockout')}> Blockout Date</div>
                <div className={atab=='taxes'?'hometabactive':'hometab'} onClick={()=>onChangeTab('taxes')}> Other taxes</div>
                <div className={atab=='size'?'hometabactive':'hometab'} onClick={()=>onChangeTab('size')}> Lot Size Availability</div>
                <div className={atab=='images'?'hometabactive':'hometab'} onClick={()=>onChangeTab('images')}> Images</div>
            </div>
           </div>
           <div>
            {atab=='lot' && 
            <div className='tabContent'>
                <form method="post" onSubmit={onSaveParkinglot}>
                                <div className="parking-info">
                                        <h3>Parking Lot Information</h3>
                                        <div className="input">
                                            <label htmlFor="lot-name">Parking Lot Name</label>
                                            <input type="text" name='parkinglotname' id='lot-name' value={activelot.parkinglotname} required onChange={lotDataChange}/>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-id">Parking Lot ID</label>
                                                <input type="text" name='parkinglotid' id='lot-id' maxLength={15} value={activelot.parkinglotid} disabled={isNew ?false:true } required onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-type">Parking Lot Type</label>
                                                <input type="text" name='parkingtype' id='lot-type' required value={activelot.parkingtype} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-state">Reservation Type</label>
                                                <select name="reservationtype" id="reservationtype" required value={activelot.reservationtype} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Reservation Parking Type</option>
                                                    <option value='cruise'>Cruise Parking</option>
                                                    <option value='hotel'>Hotel Parking</option>
                                                    <option value='both'>Cruise and Hotel Parking</option>
                                                    <option value='downtown'>Downtown Parking</option>
                                                </select>
                                            </div>
                                        </div>
                                        
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-days">Minimum Days Allowed</label>
                                                <input type="number" min={1} name='minday' id='lot-days' value={activelot.minday} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-threshold">Low Inventory Threshold</label>
                                                <input type="text" name='lowinventory' id='lot-threshold' value={activelot.lowinventory} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-code">Three Digit Code</label>
                                                <input type="text" name='threedigitcode' id='lot-code' value={activelot.threedigitcode} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-size">Parking Lot Size</label>
                                                <input type="text" name='lotsize' required id='lot-size' value={activelot.lotsize} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-contactFirst">Contact First Name</label>
                                                <input type="text" name='contactfirstname'  id='lot-contactFirst' value={activelot.contactfirstname} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-contactLast">Contact Last Name</label>
                                                <input type="text" name='contactlastname' id='lot-contactLast' value={activelot.contactlastname} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-address">Address 1</label>
                                            <input type="text" name='address1' id='lot-address' required value={activelot.address1} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-addressTwo">Address 2</label>
                                            <input type="text" name='address2' id='lot-addressTwo' value={activelot.address2} onChange={lotDataChange}/>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-city">City</label>
                                                <input type="text" name='city' id='lot-city' required value={activelot.city} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-state">State</label>
                                                <select name="state" id="user-state" required value={activelot.state} onChange={lotDataChange}>
                                                    <option value="" disabled>Select State/Province</option>
                                                    {
                                                        states.map((state, id) => {
                                                        return (
                                                            <option key={id} value={state.code}>{state.name}</option>
                                                        )
                                                        })
                                                    }
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-postcode">Postal Code</label>
                                                <input type="text" name='zipcode' id='lot-postcode' value={activelot.zipcode} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-phone">Phone</label>
                                                <input type="tel" name='phone' id='lot-phone' required value={activelot.phone} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-email">E-mail</label>
                                                <input  name='email' id='lot-email' required value={activelot.email} onChange={lotDataChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lot-fax">Fax</label>
                                                <input type="tel" name='fax' id='lot-fax' value={activelot.fax} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="short-statement">Short Security Statement</label>
                                            <textarea name='shortsecuritystatement'
                                                value={activelot.shortsecuritystatement} onChange={lotDataChange}> </textarea>
                                            {/* <ReactQuill
                                                id="short-statement"
                                                name='shortsecuritystatement'
                                                value={activelot.shortsecuritystatement}
                                                onChange={(e) => setActivelot({ ...activelot, shortsecuritystatement: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="full-statement">Full Security Statement</label>
                                        
                                            <textarea  name='fullsecuritystatement'
                                                value={activelot.fullsecuritystatement} onChange={lotDataChange}></textarea>
                                           {/*  <ReactQuill
                                                id="full-statement"
                                                name='fullsecuritystatement'
                                                value={activelot.fullsecuritystatement}
                                                onChange={(e) => setActivelot({ ...activelot, fullsecuritystatement: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lot-dist">Distance from Airport</label>
                                            <input type="text" name="distancestatement" id="lot-dist" value={activelot.distancestatement} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="transportation-statement">Transportation Statement</label>
                                            
                                            <textarea name='transportation'
                                                value={activelot.transportation} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="transportation-statement"
                                                name='transportation'
                                                value={activelot.transportation}
                                                onChange={(e) => setActivelot({ ...activelot, transportation: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="arrival-info">Arrival Information</label>
                                           
                                            <textarea name='arrivalinfo'
                                                value={activelot.arrivalinfo} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="arrival-info"
                                                name='arrivalinfo'
                                                value={activelot.arrivalinfo}
                                                onChange={(e) => setActivelot({ ...activelot, arrivalinfo: e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="parking-info">Parking Information</label>
                                            
                                            <textarea name='parkingrateinfo'
                                                value={activelot.parkingrateinfo} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="parking-info"
                                                name='parkingrateinfo'
                                                value={activelot.parkingrateinfo}
                                                onChange={(e) => setActivelot({ ...activelot,parkingrateinfo : e })}
                                            /> */}
                                        </div>
                                        <div className="input">
                                            <label htmlFor="other-info">Other Information</label>
                                            <textarea name='otherinfo'
                                                value={activelot.otherinfo} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="other-info"
                                                name='otherinfo'
                                                value={activelot.otherinfo}
                                                onChange={(e) => setActivelot({ ...activelot, otherinfo: e })}
                                            /> */}
                                        </div>                                 
                                </div>
                                <div className="another-section">
                                    
                                        <h3>Another Section</h3>
                                       
                                       
                                        
                                        <div className="input">
                                            <label htmlFor="direction">Direction</label>
                                            <textarea name='direction'
                                                value={activelot.direction} onChange={lotDataChange}></textarea>
                                            {/* <ReactQuill
                                                id="direction"
                                                name='direction'
                                                value={activelot.direction}
                                                onChange={(e) => setActivelot({ ...activelot, direction: e })}
                                            /> */}
                                        </div>
                                        <div className="inputs">
                                           
                                            <div className="input">
                                                <label htmlFor="pay-online">Full Payment Online</label>
                                                <select name="onlinepay" id="pay-online" value={activelot.onlinepay} onChange={lotDataChange}>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                           <div className="input">
                                                <label htmlFor="lot-rank">Rank</label>
                                                <input type="text" name='ranking' id='lot-rank' value={activelot.ranking} onChange={lotDataChange}/>
                                            </div> 
                                        </div>
                                       
                                       
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lot-status">Status</label>
                                                <select name="status" id="lot-status" value={activelot.status} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="receipt-barcode">Barcode on Receipt</label>
                                                <select name="generatebarcode" id="receipt-barcode" value={activelot.generatebarcode} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="barcode-type">Barcode Type</label>
                                                <select name="barcodetype" id="barcode-type" value={activelot.barcodetype} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Barcode</option>
                                                    <option value="128">Code 128</option>
                                                    <option value="39">Code 39</option>
                                                    <option value="39">Code 39</option>
                                                </select>
                                            </div>
                                            
                                          
                                        </div>
                                    <div className="head-input">
                                    <h2>PARKING RATES</h2>
                                    
                                    <div className="input">
                                        <label htmlFor="rateType">Rate Type</label>
                                        <select id="rateType" value={activelot?.rateType} name='rateType' onChange={lotDataChange} required>
                                        <option value="">Select Rate Type</option>
                                        <option value="both">Daily & Hourly Rate</option>
                                            <option value="daily">Daily</option>
                                            <option value="hourly">Hourly</option>
                                        </select>
                                    </div>   
                                    {(activelot?.rateType=='daily' || activelot?.rateType=='both')  &&  <div className="input">
                                        <label htmlFor="daily-rate">Daily Rate</label>
                                        <input type="number" id='daily-rate' value={activelot.rate} name='rate' onChange={lotDataChange} required/>
                                    </div>}
                                    {(activelot?.rateType=='hourly' || activelot?.rateType=='both') && <div>
                                            {
                                            lotHours?.length>0 &&  lotHours.map((hour, id) => {
                                                return (
                                                    <div className="inputs" key={id}>
                                           
                                                <div className="input">
                                                    <label htmlFor="holidayRateStartDate">Charge {id+1} Duration</label>
                                                    <input type="time"  value={ hour.startHr } name='startHr' onChange={(e)=>editLotHourChange(id,e)} required />
                                                </div>
                                                {/* <div className="input">
                                                    <label htmlFor="holidayRateStartDate">End Hour</label>
                                                    <input type="number"  value={ hour.endHr } name='endHr' onChange={(e)=>editLotHourChange(id,e)}  />
                                                </div> */}
                                                <div className="input">
                                                    <label htmlFor="holidayRateStartDate">Charge {id+1} Rate ($)</label>
                                                    <input type="number"  value={ hour.rate } name='rate' onChange={(e)=>editLotHourChange(id,e)} required  />
                                                </div>
                                                <div className="input">
                                                    <button style={{width:100}} type="button" className='buttons' onClick={()=> onRemoveLotHour(id)} >Remove</button>
                                                </div>
                                                
                                            </div>
                                                )
                                                })
                                            }
                                            <button type='button' onClick={()=> setLotHours([...lotHours,{parkinglotId:parkinglotid}]) }  className='buttons'>Add Hourly Rates</button>
                                    </div>}
                                    <div className="inputs">
                                        <div className="input">
                                            <label htmlFor="tax-rate">Tax Rate</label>
                                            <input type="text" name='taxrate' id='tax-rate' value={activelot.taxrate} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="service-fee">Service Fee</label>
                                            <input type="text" name='servicefee' id='service-fee' required value={activelot.servicefee} onChange={lotDataChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="access-fee">Access Fee</label>
                                            <input type="text" name='accessfee' id='access-fee' value={activelot.accessfee} onChange={lotDataChange}/>
                                        </div>              
                                        
                                    </div>
                                    </div>

                                    
                                        <div className="head-input">
                                            <h2>NETPARK INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="location-code">Netpark Location Code</label>
                                                <input type="text" name='netparkCode' id='location-code' value={activelot.netparkCode} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="netpark-email">Should Netpark send E-mail?</label>
                                                <select name="netparkCode" id="netpark-email" value={activelot.netparkCode} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>PARKONECT INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="parkonect-id">Parkonect Garage ID</label>
                                                <input type="text" name='parkconectId' id='parkonect-id' value={activelot.parkconectId} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="same-daybooking">Allow same day booking?</label>
                                                <select name="samedaybooking" id="same-daybooking" value={activelot.samedaybooking} onChange={lotDataChange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>GEO Location</h2>
                                            <div className="input">
                                                <label htmlFor="parkonect-id">Longitude</label>
                                                <input type="text" name='longitude' id='longitude' value={activelot.longitude} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="parkonect-id">Latitude</label>
                                                <input type="text" name='latitude' id='latitude' value={activelot.latitude} onChange={lotDataChange} />
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>SPACE GENIUS INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="spacegenius-id">Space Genius Channel ID</label>
                                                <input type="text" name='spaceChannelid' id='spacegenius-id' value={activelot.spaceChannelid} onChange={lotDataChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="spacegeniuscompany-id">Space Genius Company ID</label>
                                                <input type="text" name='spaceComId' id='spacegeniuscompany-id' value={activelot.spaceComId} onChange={lotDataChange}/>
                                            </div>
                                        </div>
                                        <div className="head-input">
                                            <h2>AMINO INTEGRATION</h2>
                                            <div className="input">
                                                <label htmlFor="barcode-type">Parkinglot Support Amino?</label>
                                                <select name="isAmano" id="isAmano" value={activelot.isAmano} onChange={lotDataChange}>
                                                    <option value="" disabled>Select Yes For Amino</option>
                                                      <option value={false}>No</option>
                                                    <option value={true}>Yes</option>
                                                  
                                                   
                                                </select>
                                            </div>
                                            {(activelot.isAmano=='true' || activelot.isAmano) && <div className="input">
                                                <label htmlFor="Aminofacility">Amino Facility ID </label>
                                                <input type="text" name='aminoFacility' id='Aminofacility' required value={activelot.aminoFacility} onChange={lotDataChange}/>
                                            </div>} 
                                            
                                        </div>
                                    
                                </div>
                                <button className="savebtn" type='submit'>Save</button>
                            </form>
            </div>
            }
            {atab=='holiday' && 
            <div className='tabContent'>
                        {view!='list' && <div className="main-form">
                               <div style={{textAlign:'right'}}>
                               <button className='buttons' onClick={() => setView('list')}>Back to Event Rates</button>
                               </div>
                                <div className="hol-body">
                                    { (view === 'edit' || view === 'new') && 
                                    <form className="create-lotSize" onSubmit={onSaveSpecialRate}>
                                    <h3>{view === 'edit' ? 'Edit' : 'Create'} Event Rate</h3>
                                    <p>Event rates</p>

                                    <div className="input">
                                        <label htmlFor="holidaydescription">Title</label>
                                        <input id="title" value={specialrate.title} name='title' onChange={editSpecialRateChange}/>
                                    </div>
                                   
                                    <div className="input">
                                        <label htmlFor="specialType">Type</label>
                                        <select id="specialType" value={specialrate?.specialType} name='specialType' onChange={editSpecialRateChange} required>
                                        <option value="">Select Special Type</option>
                                            <option value="holiday">Holiday</option>
                                            <option value="season">Season</option>
                                            <option value="season">Event</option>
                                        </select>
                                    </div>
                                    <div className="input">
                                        <label htmlFor="rateType">Rate Type</label>
                                        <select id="rateType" value={specialrate?.rateType} name='rateType' onChange={editSpecialRateChange} required>
                                        <option value="">Select Rate Type</option>
                                        <option value="both">Daily & Hourly Rate</option>
                                            <option value="daily">Daily</option>
                                            <option value="hourly">Hourly</option>
                                        </select>
                                    </div>   

                                    {(specialrate?.rateType=='daily' || specialrate?.rateType=='both')  &&  <div className="input">
                                        <label htmlFor="daily-rate">Daily Rate</label>
                                        <input type="number" id='daily-rate' value={specialrate.dailyRate} name='dailyRate' onChange={editSpecialRateChange} required/>
                                    </div>}
                                    {(specialrate?.rateType=='hourly' || specialrate?.rateType=='both') && <div>
                                            {
                                            hours?.length>0 &&  hours.map((hour, id) => {
                                                return (
                                                    <div className="inputs" key={id}>
                                            
                                                <div className="input">
                                                    <label htmlFor="holidayRateStartDate">Charge {id+1} Duration</label>
                                                    <input type="time"  value={ hour.startHr } name='startHr' onChange={(e)=>editHourChange(id,e)} required />
                                                </div>
                                               {/*  <div className="input">
                                                    <label htmlFor="holidayRateStartDate">End Hour</label>
                                                    <input type="number"  value={ hour.endHr } name='endHr' onChange={(e)=>editHourChange(id,e)}  />
                                                </div> */}
                                                <div className="input">
                                                    <label htmlFor="holidayRateStartDate">Charge {id+1} Rate ($)</label>
                                                    <input type="number"  value={ hour.rate } name='rate' onChange={(e)=>editHourChange(id,e)} required  />
                                                </div>
                                                <div className="input">
                                                    <button style={{width:100}} className='buttons' onClick={()=> onRemoveHour(id)} >Remove</button>
                                                </div>
                                                
                                            </div>
                                                )
                                                })
                                            }
                                            <button type='button' onClick={()=> setHours([...hours,{parkinglotId:parkinglotid}]) }  className='buttons'>Add Hourly Rates</button>
                                    </div>}
                                    
                                    <div className="inputs">
                                        <div className="input">
                                            <label htmlFor="holidayRateStartDate">Start Date</label>
                                            <input type="date"  value={ specialrate.startdate ? dateOnly(specialrate.startdate) : getTodayDate()  } name='startdate' onChange={editSpecialRateChange} required />
                                        </div>
                                        <div className="input">
                                            <label htmlFor="holidayRateEndDate">End Date</label>
                                            <input type="date"  value={ specialrate.enddate ? dateOnly(specialrate.enddate) : getTodayDate()} name='enddate' min={specialrate.startdate} onChange={editSpecialRateChange}/>
                                        </div>
                                    </div>
                                    <div className="btns">
                                        <button className='savebtn' type='submit'>Save</button>
                                        
                                    </div>
                                    </form>}
                                </div>
                            </div>}    
                          {view=='list' && 
                          <div className="body">
                    
                                <div >
                               <button className='buttons' onClick={() => {setView('new'); setSpecialrate({id:0})}}>Add Event Rates</button>
                               </div>
                                <div className="table-wrapper">
                                      {specialrates?.length>0 && <table>
                                            <thead>
                                                <tr>
                                                     <th>Title</th>
                                                     <th>Rate Type</th>
                                                    <th> Rate</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    
                                                    <th>Special Type</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    specialrates.map((hol, idx) => {
                                                        return (
                                                            <tr key={idx}>
                                                                <td>{hol.title}</td>
                                                                <td>{hol.rateType}</td>
                                                                <td>{hol.rateType=='hourly' && <div>
                                                                    <table>
                                                                        <tr>
                                                                            <th>Range</th>
                                                                            <th>Hour Rate</th>
                                                                        </tr>
                                                                        {hol.hourlyRates?.map((hour, idxx) => { return (<tr id={{idxx}}>
                                                                        <td>{hour.startHr}hr - {hour.endHr}hr</td>
                                                                        <td>{Constants.formatCurrency(hour.rate)}</td>
                                                                        </tr> )})}
                                                                        
                                                                    </table>
                                                                    </div>} {hol.rateType=='daily' && <span>${Constants.formatCurrency(hol.dailyRate) }</span>}</td>
                                                               
                                                                <td>{Constants.formatDate(hol.startDate) }</td>
                                                                <td>{Constants.formatDate(hol.endDate) }</td>
                                                                
                                                                <td>{hol.specialType}</td>
                                                                <td>
                                                                    <ul>
                                                                        <li><button onClick={() => editspecialRate(hol)} className='buttons'> Edit</button></li>
                                                                       {/*  <li className='delete'><button onClick={() => deleteSpecialRate(idx)} className='buttons bgRed'>Delete</button></li> */}
                                                                    </ul>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>}  
                                </div>
                                </div>}  
                          
            </div>
            }
            {atab=='reserved' && 
            <div className='tabContent'>
              {view=='list' && <div className="body">
                    <div className="btns">
                        
                    <button className='buttons' onClick={()=>setView('new')}>Add New Reserved Date</button>
                    </div>
                    <div className="table-wrapper">
                      {reserves?.length>0 && <table>
                            <thead>
                                <tr>
                                    <th>Reservation Date</th>
                                    <th>Reservations</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    reserves?.map((item, id) => { return ( 
                                        <tr key={id}>
                                            <td>{convertToHumanReadableDate(item.reserveDate)}</td>
                                            <td>{item.numOfReservations}</td>
                                            <td className='edit' onClick={() => editReserve(item)}>Edit</td>
                                        </tr>
                                    ) })
                                }
                            </tbody>
                        </table>}  
                    </div>
                </div>}  
              {view!='list' && <div className="body">
                                    <h3>Create Reserved Date</h3>
                                    <div className="btns" style={{textAlign:'right'}}>
                                        
                                        <button className='buttons' onClick={() => setView('list')}>Back to Reserved Dates</button>
                                    </div>
                                    <form>
                                        <div className="createDate">
                                            <div className="input">
                                                <label htmlFor="create-booked-date">Booked Date</label>
                                                <input type="date" name="reservationdates" id="create-booked-date" onChange={editReserveChange} />
                                            </div>
                                            <div className="input">
                                                <label htmlFor="reserve-count">Reservation Count</label>
                                                <input type="number" name="reservations" id="reserve-count" onChange={editReserveChange} />
                                            </div>
                                            <button className='savebtn' type='submit'>Save</button>
                                        </div> 
                                    </form>
                                    
                            </div>}  
            </div>
            }
    
            {atab=='blockout' && 
            <div className='tabContent'>
              {view!='list' && <div className="main-form">
                   
                    <div style={{textAlign:'right'}}>
                               <button className='buttons' onClick={() => setView('list')}>Back to Blockout Dates</button>
                               </div>
                    <div className="body">
                                    {
                                        (view === 'edit' || view === 'new') && 
                                        <form className="create-lotSize" onSubmit={onBlockout}>
                                        <h3>{view === 'edit' ? 'Edit' : 'Create'} Blockout Date</h3>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="blockoutStart">Start Date</label>
                                                <input type="date" id='blockoutStart' value={blockPicked.startdate ? dateOnly(blockPicked.startdate) : getTodayDate()} name='startdate' onChange={editblockchange} required/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="blockoutEnd">End Date</label>
                                                <input type="date" id='blockoutEnd' value={blockPicked.enddate ? dateOnly(blockPicked.enddate) : getTodayDate()} name='enddate' onChange={editblockchange} required/>
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <button className='savebtn' type='submit'>Save</button>
                                            
                                        </div>
                                    </form>
                                    }
                                </div>
                </div>}  

                {view=='list' && <div className="body">
                                
                                <div>
                               <button className='buttons' onClick={() => setView('new')}>Add Blockout Dates</button>
                               </div>
                                <div className="table-wrapper">
                                   {blockoutDates?.length>0 && <table>
                                            <thead>
                                                <tr>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th>Last Updated</th>
                                                    <th>Updated By</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    blockoutDates?.map((item, id) => {return ( item!=null &&
                                                <tr key={id}>
                                                    <td>{changeDate(item.startdate)}</td>
                                                    <td>{changeDate(item.enddate)}</td>
                                                    <td>{changeDate(item.updatedate)}</td>
                                                    <td>{item.updatedby}</td>
                                                    <td className='edit' onClick={() => deleteBlockout(item.recordid)}>Delete</td>
                                                </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>}     
                                </div>
                                </div>}
                
            </div>
            }
            {atab=='taxes' && 
            <div className='tabContent'>
              {view!='list' && <div className="main-form">
                   
                    <div style={{textAlign:'right'}}>
                               <button className='buttons' onClick={() => setView('list')}>Back to Other Taxes</button>
                               </div>
                   

                    <div className="body">
                                    {
                                        (view === 'edit' || view === 'new') && <form className="create-lotSize" onSubmit={onTax}>
                                        <h3>{view === 'edit' ? 'Edit' : 'Create'} Tax</h3>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="taxName">Tax Name</label>
                                                <input type="text" id='taxName' value={otherTaxPicked.taxname} name='taxname' onChange={edittaxchange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxValue">Tax Value</label>
                                                <input type="number" id='taxValue' value={otherTaxPicked.taxvalue} name='taxvalue' onChange={edittaxchange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxUnit">Tax Unit</label>
                                                <select id="taxUnit" value={otherTaxPicked?.taxtype} name='taxtype' onChange={edittaxchange}>
                                                    <option value="" disabled>Select Unit</option>
                                                    <option value="DOLLAR">$</option>
                                                    <option value="PERCENT">%</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxBasis">Tax Basis</label>
                                                <select id="taxBasis" value={otherTaxPicked?.taxbasis} name='taxbasis' onChange={edittaxchange}>
                                                    <option value="" disabled>Select Basis</option>
                                                    <option value="Per Day">Per Day</option>
                                                    <option value="Per Reservation">Per Reservation</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxStatus">Tax Status</label>
                                                <select id="taxStatus" value={otherTaxPicked?.taxstatus} name='taxstatus' onChange={edittaxchange}>
                                                    <option value="" disabled>Select Tax Status</option>
                                                    <option value="Active">Active</option>
                                                    <option value="Inactive">Inactive</option>
                                                </select>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="taxFreeDays">Tax Free Days</label>
                                                <select id="taxFreeDays" value={otherTaxPicked?.taxfreedays} name='taxfreedays' onChange={edittaxchange}>
                                                    <option value="" disabled></option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <button type='submit' className='savebtn'>Save</button>
                                            
                                        </div>
                                        </form>
                                    }
                                </div>
                </div>}  
             { view=='list' && <div className="body">
                                
                                <div >
                               <button className='buttons' onClick={() => setView('new')}>Add New Tax</button>
                               </div>
                                <div className="table-wrapper">
                                    {otherTaxes?.length>0 && <table>
                                            <thead>
                                                <tr>
                                                    <th>Tax Name</th>
                                                    <th>Tax Amount</th>
                                                    <th>Tax Type</th>
                                                    <th>Tax Basis</th>
                                                    <th>Tax Free Days</th>
                                                    <th>Create Date</th>
                                                    <th>Last Modified Date</th>
                                                    <th>Status</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    otherTaxes?.map((item, idx) => { return (
                                                    <tr key={idx}>
                                                        <td>{item.taxname}</td>
                                                        <td>${item.taxvalue}</td>
                                                        <td>{item.taxtype}</td>
                                                        <td>{item.taxbasis}</td>
                                                        <td>{item.taxfreedays}</td>
                                                        <td>{changeDate(item.createdate)}</td>
                                                        <td>{changeDate(item.updatedate)}</td>
                                                        <td>{item.taxstatus}</td>
                                                        <td className='edit' onClick={() => editTax(item)}>Edit</td>
                                                    </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>}    
                                </div>
                                </div>}   
            </div>
            }
            {atab=='size' && 
            <div className='tabContent'>
                {view!='list' && <div className="main-form">
                        
                        <div style={{textAlign:'right'}}>
                               <button className='buttons' onClick={() => setView('list')}>Back to Daily Lots</button>
                               </div>
                        <div className="lot">
                                    {(view === 'edit' || view === 'new') && 
                                    <form className="create-lotSize">
                                        <h3>{view === 'new' ? 'Create' : 'Edit'} Lot Size</h3>
                                        <div className="input">
                                            <label htmlFor="lotsizenum">Lot Size</label>
                                            <input type="number" id='lotsizenum' value={lotsizePicked.lotsize} name='lotsize' onChange={lotsizeChange}/>
                                        </div>
                                        <div className="input">
                                            <label htmlFor="lotdescription">Description</label>
                                            <textarea id="lotdescription" value={lotsizePicked.description} name='description' onChange={lotsizeChange}></textarea>
                                        </div>
                                        <div className="inputs">
                                            <div className="input">
                                                <label htmlFor="lotsizeStartDate">Start Date</label>
                                                <input type="date" id='lotsizeStartDate' value={lotsizePicked.startdate} name='startdate' onChange={lotsizeChange}/>
                                            </div>
                                            <div className="input">
                                                <label htmlFor="lotsizeEndDate">End Date</label>
                                                <input type="date" id='lotsizeEndDate' value={lotsizePicked.enddate} name='enddate' onChange={lotsizeChange}/>
                                            </div>
                                        </div>
                                        <div className="btns">
                                            <button type='submit' className='savebtn'>Save</button>
                                           
                                        </div>
                                    </form>}
                                </div>
                    </div>}    
                 {view=='list' && <div className="body">
                               
                                <div>
                               <button className='buttons' onClick={() => setView('new')}>Add Daily Parking Lot Size</button>
                               </div>
                                <div className="table-wrapper">
                                  {lotSizes?.length>0 && <table>
                                            <thead>
                                                <tr>
                                                    <th>Lot Size</th>
                                                    <th>Description</th>
                                                    <th>Start Date</th>
                                                    <th>End Date</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    lotSizes?.map((item, i) => { return  (
                                                <tr key={i}>
                                                    <td>{item.lotsize}</td>
                                                    <td>{item.description}</td>
                                                    <td>{item.startdate}</td>
                                                    <td>{item.enddate}</td>
                                                    <td className='edit' onClick={() => editLotSize(item)}>Edit</td>
                                                </tr>
                                                    )})
                                                }
                                            </tbody>
                                        </table>}      
                                </div>
                                </div>}   
            </div>
            }
            {atab=='images' && 
            <div className='tabContent'>
          {view=='list' && <div className="body">
               

                    <div className="btns">
                    </div>
                        <div>
                            <button className='buttons' onClick={() => setView('new')}>Add Parking Lot Image</button>
                        </div>
                    <div className="table-wrapper">
                     { activelot?.lotImages.map((img, id) => { return ( 
                                  <div key={id} >
                                    <img src={`${Constants.baseUrl}/resources/cms/lot/${img.imagename}`} alt={img.imagelocation} style={{width:400}} />
                                  
                                   <div><span>{img.imagelocation}</span></div>
                                   <button className='buttons' onClick={() => onRemoveLotImage(img,id)}>Remove Image</button>
                                  </div>
                                  
                                ) })}  
                    </div>
                </div>}  
          {view!='list' &&  <div className="body">
                                    <h3>Add Parking Lot Image</h3>
                                   
                                    <div style={{textAlign:'right'}}>
                               <button className='buttons' onClick={() => setView('list')}>Back to Parking Lot Images</button>
                               </div>
                               <form onSubmit={onSaveImage}>
                                <div className="input">
                                    <label htmlFor="holidayRateStartDate">Image</label>
                                    <input
                                      type="file"
                                      id="upload-input"
                                      accept="image/*"
                                      onChange={handleImageChange}
                                  />
                                </div>
                                <div className="input">
                                    <label htmlFor="holidayRateStartDate">Alt</label>
                                    <input type="text"  name='alttext' value={lotImage.alt} onChange={(e)=>setLotImage({...lotImage,alt: e.target.value})} required />
                                </div> 
                                {loading && <img src={LoadingImg} height={50} alt='Loading...' />}     
                                <div className="btns">
                                        <button className='savebtn' type='submit'>Save Image</button>
                                    </div>

                          </form>
                                    
                            </div>}     
            </div>
            }
           </div>
           
        </div>
        {alert.open && <div className={ "congrats show" }>
                            <ApiAlert {...alert} onOk={onAlertOk}/>
                        </div> }
            {loading &&  <div className={ "congrats show" }>
                            <Loading />
                        </div>}  
            { isExpired && <div className={  'congrats show'}> <Expired mode='admin' /> </div>}  
    </div>
  )
}

export default NewParkinglot