import React, {useEffect,  useState, useContext} from 'react'
import '../../styles/User/directions.css'
import '../../styles/User/home.css'

import TopBar from '../../components/User/TopBar'
import * as Constants from '../../constants';
import ReactGA from 'react-ga';
import { useParams, useNavigate, Link } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';
import AirportForm from '../../components/User/AirportForm';
import { useInView } from 'react-intersection-observer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft, faAngleRight, faCircleXmark, faLocationDot, faMap } from '@fortawesome/free-solid-svg-icons'

function ReservationType() {
  const { formData, searchData, setSearchData , setFormData} = useContext(ShareContext);
    const {restype}=useParams();
    const [lots, setLots]=useState([]);
    const [selectedLots, setSelectedLots]=useState([]);
    const [place, setPlace]=useState({});
    const [html, setHtml]=useState({});
    const { ref: mainRef, inView: isMainInView } = useInView();


    const cruisef1={head:'New Orleans Cruise Parking',
      title:'Convenient, Affordable, and Secure Parking for Your Cruise Adventure', 
      sub:'Planning a cruise from the Port of New Orleans? Ensure a smooth start to your journey with our convenient, affordable, and secure parking options. Located just minutes from the cruise terminals, our parking facilities offer the perfect solution for travelers looking for peace of mind and easy access to their cruise ship.',
    list:[{title:'Prime Location',sub:'Our parking lots are strategically located near the Erato Street and Julia Street cruise terminals, making it easy for you to park and embark on your cruise without any hassle. Enjoy a stress-free walk or take advantage of our complimentary shuttle service to the terminal.'},
      {title:'Affordable Rates',sub:' We understand that travel expenses can add up quickly. That’s why we offer competitive daily rates, ensuring you get the best value for your money. Whether you’re going on a short getaway or an extended cruise, our pricing is designed to fit your budget.'},
      {title:'Secure Parking',sub:'Your vehicle’s safety is our top priority. Our parking facilities are well-lit, fenced, and monitored 24/7 by security personnel and surveillance cameras. You can relax and enjoy your cruise knowing your car is in good hands.'},
      {title:'Easy Online Reservations',sub:'Skip the stress of finding a parking spot on the day of your departure. With our easy-to-use online reservation system, you can book your parking space in advance. Simply select your dates, reserve your spot, and arrive with confidence.'},
      {title:'Exceptional Shuttle Service',sub:'Our friendly and professional staff are here to assist you every step of the way. From helping with luggage to providing directions, we’re committed to making your parking experience as smooth and pleasant as possible.'},
      {title:'',sub:''},
    ], how:'Use our online reservation system to book your parking space in advance. Follow the easy directions to our parking facility, where our staff will assist you with parking and luggage. Take advantage of our complimentary shuttle service to the cruise terminal, or enjoy a short walk if you prefer. Relax and enjoy your cruise, knowing your vehicle is safe and secure. Upon your return, our shuttle will bring you back to the parking lot, where your car will be waiting for you',
    book:'Don’t let parking stress ruin the start of your cruise. Reserve your spot with us today and enjoy a seamless, worry-free parking experience'
  }
    const hotelf1={head:'New Orleans Hotel Parking', title:'Reserve Your NOLA Parking',
       sub:'Welcome to the heart of the Crescent City! Whether you’re visiting for business or pleasure, we’ve got your parking needs covered. Reserve your spot now and enjoy hassle-free parking near your New Orleans hotel.', 
      list:[{title:'Prime Location',sub:'You can park and get started on your trip straight away because we have ideal parking near hotels in New Orleans, adjacent to significant landmarks. Take a stroll without tension'},
            {title:'Reasonably Priced',sub:'We understand that travel expenses can rise quickly. We provide affordable daily pricing to ensure you receive the best value for your money. Whether you are planning a short trip or a longer stay, our price is reasonable'},
            {title:'Secure Parking',sub:'The security of your vehicle is our first priority. Our parking lots are constantly supervised, well-lit, and safe. You can relax and enjoy your time at your hotel in New Orleans knowing that your car is in good hands'},
            {title:'Simple Online Reservations',sub:'Save yourself the trouble of finding a parking spot on the day of your arrival. With our easy-to-use online reservation system, you may reserve your parking spot in advance. Simply choose your dates, reserve your spot, and arrive confidently'},
            
          ], how:'Use our online reservation system to guarantee your parking space in advance. Just follow the instructions to get to our parking lot. You can relax and enjoy your stay at your New Orleans hotel knowing that your automobile is safe and secure. When you return, your car will be ready for you',
          book:'Avoid letting your trip to New Orleans be ruined by parking worries. Get your spot reserved with us right now to take advantage of a smooth, stress-free parking experience.'
    };
    const downtownf1={head:'Downtown New Orleans Parking',
       title:'Reserve Your Parking Spot in Downtown New Orleans',
        sub:'Welcome to the heart of the Crescent City! Whether you’re visiting for business or pleasure, we’ve got your parking needs covered. Reserve your spot now and enjoy hassle-free parking in the vibrant downtown area.', 
      list:[{title:'Prime Location',sub:'Our parking facilities are conveniently situated throughout downtown New Orleans, close to important icons, so you can park and start your journey right away. Go for a stress-free stroll.'},
            {title:'Affordable Rates',sub:'We are aware that travel costs can go up rapidly. To make sure you get the most for your money, we offer competitive daily pricing. Our pricing is tailored, whether you are planning a quick trip or a longer stay'},
            {title:'Secure Parking',sub:'Our first concern is the safety of your car. Our parking lots are secure, well-lit, and under constant supervision. Knowing that your automobile is in capable hands allows you to unwind and relish your time in downtown New Orleans.'},
            {title:'Easy Online Reservations',sub:'On the day of, avoid the hassle of locating a parking space. Your parking space can be reserved in advance with our user-friendly online reservation system. Just pick your dates, secure your place, and show up with assurance'},
         
          ], how:'To reserve your parking spot in advance, use our online reservation system. To access our parking area, simply follow the directions. Knowing that your car is secure and safe allows you to unwind and enjoy your time in downtown New Orleans. Your car will be waiting for you when you get back.',
          book:'Don’t let parking stress ruin your time in New Orleans. Reserve your spot with us today and enjoy a seamless, worry-free parking experience.'
      };
    const mainf1=restype=='cruise'?cruisef1:(restype=='hotel'?hotelf1:downtownf1);
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search); 
        
        if(restype=='cruise'){
          setFormData({...formData,reservationtype:restype, rateOption:'daily', rateType:'daily'});
          
      }else if(restype=='hotel'){
          setFormData({...formData,reservationtype:restype, rateOption:'daily', rateType:'daily'});
         
      }else if(restype=='downtown'){
          setFormData({...formData,reservationtype:restype, rateOption:'hourly',rateType:'hourly'});
      
      }
     
        loadLots();
        //set figure
        setSearchData(null);

    },[restype]);
 
    const loadLots=()=>{
        fetch(`${Constants.apiUrl}/reservation/cms/parkinglotsbyType/${restype}`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
            }
          })
          .then((resp) => resp.json())
          .then((data) =>  {
           
            setLots(data);
          
           // console.log(data);
            setSelectedLots(data);
            
    })
          .catch((error) => {
            console.log(error);
            
          })
        }


       

  return (
    <div className='directions'>
        <TopBar />
        <div>
         <div className={ isMainInView ? "main-box show" : "main-box"} ref={mainRef}>
           
           
           <div className="form">
           <h1 style={{color: '#fff', fontSize:'2.0em'}}>{mainf1.head}</h1>
               <div class="col-md-12" style={{marginTop:24}}>
                  
                   <p style={{color:'white', fontSize:16, backgroundColor:'#00000080', padding:16, borderRadius:10}}>New Orleans Parking helps you save time and money on your cruise and hotel parking.</p>
               </div>
               <AirportForm reservationId={0} showTab={false}/>
               <div class="col-md-12 intro" style={{marginTop:24}}>
          
       </div>
           </div>
       </div>
         </div>
        <div className="main-page" style={{maxWidth:'80%', marginLeft:'auto', marginRight:'auto'}}>
         
          <div style={{display:'flex', flexDirection:'row'}}>
            <div style={{flex:1}}>
                
                <img src={`${Constants.baseUrl}/resources/home/FrenchQuarter2_new.jpg`} alt="" style={{maxWidth:400}} />
                
            </div>
            <div  style={{flex:1, padding:16,}}>
                <h1 style={{marginBottom:24}}>{Constants.FirstLetterCap(restype) } {mainf1.title}</h1>
                <div>
                    <p>{mainf1.sub}</p>
                </div>
            </div>
          </div>
          
        
         <div style={{marginTop:24, marginBottom:24}}>
          <h2>Parking Lots</h2>
            <div>
            {selectedLots?.map((lot, id) => { return ( 
                                  <div key={id} className='speciallot' >
                                   {/*  <img src={`${Constants.baseUrl}/resources/cms/specialplaces/${img.path}`} alt={img.alt} style={{width:100}} /> */}
                                   <div style={{flex:2}}>
                                    <h3>{lot.parkinglotname}</h3>
                                    {lot.lotImages?.length>0 && <img src={`${Constants.baseUrl}/resources/cms/lot/${lot.lotImages[0].imagename}`} alt={lot.lotImages[0].imagelocation} style={{height:300}} />} 
                                    <p>{lot.address1}</p>
                                   </div>
                                   <div style={{flex:1}}>
                                    
                                    <div>
                                    {(lot.rateType=='daily' || lot.rateType=='both') && <p style={{marginBottom:16}}>Overnight <strong>${lot.rate}</strong></p> }
                                                {(lot.rateType=='hourly' || lot.rateType=='both') && 
                                                <table>
                                                    <tr>
                                                        <th>Duration</th>
                                                        <th>Rate</th>
                                                    </tr>
                                                    {lot?.hourlyRates?.map((hour, idxx) => { return (<tr id={{idxx}}>
                                                    <td style={{textAlign:'center'}}>{Constants.formatTime2(hour.startHr)}</td>
                                                    <td style={{textAlign:'center'}}>{Constants.formatCurrency(hour.rate)}</td>
                                                    </tr> )})}
                                                    
                                                </table>
                                                }
                                    </div>
                                    <div>
                                      <Link to={`/reservation/lot/${lot.parkinglotid}`}>
                                      <button className='buttons'>Book Reservation</button>
                                      </Link>
                                      
                                    </div>
                                   </div>
                                  </div>
                                  
                                ) })}
            </div>
          </div>  
          <div>
            <h2>Why Choose Us?</h2>
            <div style={{display:'flex', flexFlow:'wrap', flexDirection:'row'}}>
            {mainf1.list?.map((why, idx) => 
              { return (<div className='choose' >
                  <h3>{why.title}</h3>
                  <p>{why.sub}</p>
              </div> )})}           
            </div>
          </div>
          <div style={{marginTop:16}}>
            <h2>How It Works</h2>
            <p>{mainf1.how}</p>
          </div>
          <div style={{marginTop:16}}>
            <h2>Book Your Parking Today!</h2>
            <p>{mainf1.book}</p>
          </div>
        </div>
    </div>
  )
}

export default ReservationType

