import React, {useEffect,  useState, useContext} from 'react'
import '../../styles/User/directions.css'

import TopBar from '../../components/User/TopBar'
import * as Constants from '../../constants';
import ReactGA from 'react-ga';
import { useParams, useNavigate, Link } from 'react-router-dom'
import { ShareContext } from '../../context/ShareContext';

function PreviewPlace() {
  const { formData, searchData, setSearchData , setFormData} = useContext(ShareContext);
    const {url}=useParams();
    const [lots, setLots]=useState([]);
    const [selectedLots, setSelectedLots]=useState([]);
    const [place, setPlace]=useState({});
    const [html, setHtml]=useState({});

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search); 
        
        loadPlace();

        //set figure
        setSearchData(null);

    },[url]);
 
    const loadLots=(pl)=>{
        fetch(`${Constants.apiUrl}/reservation/cms/parkinglots`, {
            method: 'GET',
            headers: {
              'Content-type': 'application/json',
            }
          })
          .then((resp) => resp.json())
          .then((data) =>  {
           
            setLots(data);
            var sellots=pl.parkinglotIds==null?[]:pl.parkinglotIds.split(',');
            var filteredData_ = data?.filter(item => sellots.includes(item.parkinglotid) );
            console.log(filteredData_);
            setSelectedLots(filteredData_);
            
    })
          .catch((error) => {
            console.log(error);
            
          })
        }


        const loadPlace=()=>{

          var finalurl= Constants.isNumberValid(url)? `${Constants.apiUrl}/reservation/cms/placesbyid/${url}` : `${Constants.apiUrl}/reservation/cms/places/${url}`;

            fetch(finalurl, {
                method: 'GET',
                headers: {
                  'Content-type': 'application/json',
                }
              })
              .then((resp) => resp.json())
              .then((data) =>  {
               
                if(data!=null){
                    setPlace(data);
                    Constants.seo({title:data.title, metaDescription:data.meta});
                    setHtml(Constants.b64decode(data.note) )
                    //load lots 
                    loadLots(data);
                }
                    
                
              })
              .catch((error) => {
                console.log(error);
                
              })
            }

       

  return (
    <div className='directions'>
        <TopBar />
        <div className="main-page" style={{maxWidth:'80%', marginLeft:'auto', marginRight:'auto'}}>
 <h1>{place.title}</h1>
          <h3>{place.subTitle}</h3>
          <div className='specialplace'>
            <div><div dangerouslySetInnerHTML={{__html: html }}></div></div>
            <div><div style={{marginTop:24, marginBottom:24}}>
          <h2>Near by Parking Lots</h2>
            <div>
            {selectedLots?.map((lot, id) => { return ( 
                                  <div key={id} className='speciallot' style={{display:'flex', flexDirection:'row'}}>
                                   {/*  <img src={`${Constants.baseUrl}/resources/cms/specialplaces/${img.path}`} alt={img.alt} style={{width:100}} /> */}
                                   <div style={{flex:2}}>
                                    <h3>{lot.parkinglotname}</h3>
                                    <p>{lot.address1}</p>
                                    {lot.longitude!=null && place.longitude!=null && <p>Distance: {Constants.getDistanceFromLatLonInMile(lot.latitude,lot.longitude,place.latitude,place.longitude)} mile(s)</p>}
                                   {lot.lotImages?.length>0 && <img src={`${Constants.baseUrl}/resources/cms/lot/${lot.lotImages[0].imagename}`} alt={lot.lotImages[0].imagelocation} style={{height:300}} />} 
                                   </div>
                                   <div style={{flex:1}}>
                                    
                                    <div>
                                    {(lot.rateType=='daily' || lot.rateType=='both') && <p style={{marginBottom:16}}>Overnight <strong>${lot.rate}</strong></p> }
                                                {(lot.rateType=='hourly' || lot.rateType=='both') && 
                                                <table>
                                                    <tr>
                                                        <th>Duration</th>
                                                        <th> Rate</th>
                                                    </tr>
                                                    {lot.hourlyRates?.map((hour, idxx) => { return (<tr id={{idxx}}>
                                                    <td style={{textAlign:'center'}}>{Constants.formatTime2(hour.startHr)}</td>
                                                    <td style={{textAlign:'center'}}>{Constants.formatCurrency(hour.rate)}</td>
                                                    </tr> )})}
                                                    
                                                </table>
                                                }
                                    </div>
                                    <div>
                                      <Link to={`/reservation/lot/${lot.parkinglotid}`}>
                                      <button className='buttons'>Book Reservation</button>
                                      </Link>
                                      
                                    </div>
                                   </div>
                                  </div>
                                  
                                ) })}
            </div>
          </div>  </div>
          </div>
         
        
         
        </div>
    </div>
  )
}

export default PreviewPlace