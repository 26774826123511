import moment from "moment";
export const baseUrl="https://app.neworleansparking.com"; // https://app.neworleansparking.com
//export const baseUrl="https://localhost:7101";
export const MAPBOX_ACCESS_TOKEN='pk.eyJ1IjoiYWZyaXByb2cxIiwiYSI6ImNqcXkweGxxdDA3dDI0OW5yZzNwbjd2M2cifQ.vuNbqCxrJJ1_eWzyJ_FbGg';
export const excelPath=`${baseUrl}/`;

export const apiUrl=`${baseUrl}/api`;
export const PREFIX='NW';

export const formatDateTime=(inputDateTime)=> {
    /* const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
    return formattedDateTime; */
    return moment(inputDateTime).format("MMM DD, YYYY HH:mm");
  }
  export const formatDate=(inputDateTime)=> {
  
  try {
    const options = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
    return formattedDateTime;
  } catch{
    return 'N/A';
  }
  }
  export const formatTime=(inputDateTime)=> {
    const options = {
     
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    };
  
    const formattedDateTime = new Intl.DateTimeFormat('en-US', options).format(new Date(inputDateTime));
    return formattedDateTime;
  }
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: 'USD',
  });
  export const formatCurrency=(num)=>{
    // return parseFloat(num).toFixed(2);
    return formatter.format(num);
   }
  export const dateOnly=(value) =>{
    let dstring=  moment(value).format("YYYY-MM-DD");
    return dstring;
    //let d=new Date(dstring);
    //return d;
   }

   export function seo(data = {}) {
    data.title = data.title || 'New Orleans Parking.';
    data.metaDescription = data.metaDescription || 'Guaranteed New Orleans Parking.';
  
    document.title = data.title;
    document.querySelector('meta[name="description"]').setAttribute('content', data.metaDescription);
  }

  export const removeSpace=(v)=>{
    if (v==null)
     return 'NAApll';
   return v.replace('-','');
  }

  export const duration=(start, end)=>{
    let mstart = moment(start);
    let mend = moment(end);
   // let days = dateOne.diff(dateTwo, 'days')
    var duration = moment.duration(mend.diff(mstart));
    //var hours = duration.asHours(); duration.asDays duration.asMinutes

      return `${duration.days()}days, ${duration.hours()}hr`
  }
  export const isDateValid=(dateStr) => {
    return !isNaN(new Date(dateStr));
  }
  export const isNumberValid=(v) =>  {
    var vv=v*3;
   // console.log(`the value is ${vv} ---> ${isNaN( vv)}`);
    return !isNaN( vv);
  }

  export const NetworkFailed='Somehow network not connecting. Please refresh when you sure network is fine';
  export const NullableStr=(e)=> e==null?'':e;
  export const formatDate_string = (inputDate) => {
    // Split the input date into year, month, and day
    const [year, month, day] = inputDate.split('-');

    // Create a Date object using the extracted values
    const dateObject = new Date(year, month - 1, day);

    // Array of month names
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    // Get the day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
    const dayOfWeek = dateObject.getDay();

    // Array of day names
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    // Get the actual day and month names
    const actualDay = daysOfWeek[dayOfWeek];
    const actualMonth = monthNames[parseInt(month, 10) - 1];

    // Assemble the formatted date string
    const formattedDate = `${actualDay} ${actualMonth} ${day}, ${year}`;

    return formattedDate;

  };
  export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    if(file){
      reader.readAsDataURL(file);
    }
   // reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
});
export const b64encode = (txt)=> { 
  try{
      return btoa(unescape(encodeURIComponent(txt))); 
  }catch{
    console.log('btob error')
    return '';
  }

};
export const b64decode = (txt) =>{ 
  
  try{
return decodeURIComponent(escape(atob(txt))); 
  }catch{
    console.log('atob error')
    return '';
  }
};
export const ReplaceAll=(str,str1, str2,)=>
  {
   //return str.replace(/replaceWhat/g,replaceTo);
  const ignore=true;
    return str.replace(new RegExp(str1.replace(/([\/\,\!\\\^\$\{\}\[\]\(\)\.\*\+\?\|\<\>\-\&])/g, "\\$&"), (ignore ? "gi" : "g")), (typeof(str2) == "string") ? str2.replace(/\$/g, "$$$$") : str2);
  
  }
  export const FirstLetterCap=(v)=>{
  return v.replace(/^./, str => str.toUpperCase())
  }

  export const formatTime2 = (timeString) => {
    const duration = moment.duration(timeString);

    const hours = duration.hours();
    const minutes = duration.minutes();

    if (hours > 0) {
        return `${hours}${hours>1?'hrs':'hr'}`;
    } else if (minutes > 0) {
        return `${minutes}min`;
    } else {
        return `0min`; // Handle the case where time is 00:00:00
    }
};
export const getDistanceFromLatLonInKm=(lat1,lon1,lat2,lon2) => {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1); 
  var a = 
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ; 
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
  var d = R * c; // Distance in km
  return d;
}

function deg2rad(deg) {
  return deg * (Math.PI/180)
}

export const getDistanceFromLatLonInMile=(lat1,lon1,lat2,lon2) => {
   var mil=getDistanceFromLatLonInKm(lat1,lon1,lat2,lon2);
   return (mil*0.621371).toFixed(2);
}