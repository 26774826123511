import React, { useContext , useEffect, useState} from 'react'
import '../../styles/User/searchresult.css'
import TopBar from '../../components/User/TopBar'
import AirportForm from '../../components/User/AirportForm'
import { ShareContext } from '../../context/ShareContext';
import { Link } from 'react-router-dom';
import * as Constants from '../../constants';
import { useParams } from 'react-router-dom'
import ReactGA from 'react-ga';

function LotLanding() {

    const { formData, isFormSubmitted, searchData, setFormData , setSearchData, setIsFormSubmitted, hrates, setHrate} = useContext(ShareContext)

    const {selectedlotId} = useParams();
    
    const [loading, setLoading]=useState(false);
    const [rdata, setData]=useState(null);

    const [lot, setLot] =useState(null);
//console.log(`formData ${formData.reservationtype}`);
//console.log(formData)
    //   console.log(searchData);
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
        loadLot();
       
        //load default for 
    }, [])
   

const onResearch=async()=>{
    //call and replace the parkinglot item
    //formData.rateOption
    //formData.rateType
    //formData.reservationtype
    var searchReservation ={...formData, reservationtype:'unknown'}; //form data
    //console.log(searchReservation);
var dddata={...searchReservation,endDateTime: (searchReservation.rateType=='daily'? searchReservation.endDate:searchReservation.startDate) +" "+searchReservation.endTime};
   // console.log("making a re- search again to find out what is bringing");
   // console.log(dddata);
   
    setLoading(true);
   // e.preventDefault();
  //  console.log(JSON.stringify(dddata));
    try {
        const resp = await fetch(
            `${Constants.apiUrl}/reservation/search/${selectedlotId}`, {
          method: 'POST',
          body: JSON.stringify(dddata),
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        const responseData = await resp.json();
       
       // console.log(responseData)
        if (responseData.code === 0) {
          // setData(responseData.parking);
          setSearchData({parkings:[responseData.parking], numofdays:0})
          setIsFormSubmitted(true);
        }else {
          console.log(responseData.status);
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
}
const loadLot=(pl)=>{
    fetch(`${Constants.apiUrl}/reservation/cms/parkinglots/${selectedlotId}`, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json',
        }
      })
      .then((resp) => resp.json())
      .then((data) =>  {
       
        setLot(data);
        setFormData({...formData,rateOption:data.rateType,rateType:(data.rateType=='both'?'daily':data.rateType), reservationtype:onResearch.reservationtype});
        Constants.seo({title: data.parkinglotname+" New Orleans Parking", metaDescription:data.parkinglotname});
        setHrate(data.hourlyRates);
})
      .catch((error) => {
        console.log(error);
        
      })
    }

  return (
    <div className='search-result'>
        <TopBar />
        <div className="body">
            <AirportForm reservationId={0} isWidget={true} hourlyrates={lot?.hourlyRates} parkinglotid={selectedlotId} />
            <div style={{display:'flex', marginRight:'auto', marginLeft:'auto', maxWidth:600, width:'50%'}}>
            <button className='buttons'  style={{minWidth:300, marginTop:16}} onClick={onResearch}>Apply</button> 
        </div>

{lot!=null &&<div style={{width:'80%'}}> <div className='speciallot' style={{display:'flex', flexDirection:'row'}}>
                                   {/*  <img src={`${Constants.baseUrl}/resources/cms/specialplaces/${img.path}`} alt={img.alt} style={{width:100}} /> */}
                                   <div style={{flex:2}}>
                                    <h3>{lot.parkinglotname}</h3>
                                    <p>{lot.address1}</p>
                                    <p style={{marginTop:16}}>{lot.distancestatement}</p>
                                   </div>
                                   <div style={{flex:1}}>
                                    
                                    <div>
                                    {(lot.rateType=='daily' || lot.rateType=='both') && <p style={{marginBottom:16}}>Overnight <strong>${lot.rate}</strong></p> }
                                                {(lot.rateType=='hourly' || lot.rateType=='both') && 
                                                <table>
                                                    <tr>
                                                        <th>Duration</th>
                                                        <th>Rate</th>
                                                    </tr>
                                                    {lot.hourlyRates?.map((hour, idxx) => { return (<tr id={{idxx}}>
                                                    <td style={{textAlign:'center'}}>{Constants.formatTime2(hour.startHr) } </td>
                                                    <td style={{textAlign:'center'}}>{Constants.formatCurrency(hour.rate)}</td>
                                                    </tr> )})}
                                                    
                                                </table>
                                                }
                                    </div>
                                   
                                   </div>
                                  </div>
                                  <div>
                                    <div style={{margin:16}}><h3>Transport </h3><p>{lot.transportation}</p></div>
                                    <div  style={{margin:16}}><h3>Rate details </h3><p>{lot.parkingrateinfo}</p></div>
                                  </div>
                                  </div>}

          {searchData!=null && <div className="results">
                <div className="title">
                    <h2>Reservations</h2>
                   
                    {formData.rateType=='daily' && <div className="edit-text">
                        <h3>Search Results  {searchData?.numofdays} days, from {formData.startDate}  to {formData.endDate}</h3>
                        </div>} 

                        {(formData.rateType=='hourly' ) && <div className="edit-text">
                            <p>search for {formData.startDate} starting at hour {formData.startTime} to {formData.endTime}  </p>
                            
                        </div>} 
                </div>

                { isFormSubmitted ? <div className="cards">
                    { searchData?.parkings.map((item, idx) => {
                         const createMarkup = (content) => ({ __html: (content ?? '').replace(/<br\s*\/?>/g, '\n') });

                        return (
                            <div className="card" key={idx}>
                                <div className="left-note">
                                    <h3>{item.parkinglotname}</h3>
                                 {/*   {item.holidaydaysinavg.length>0 && 
                                    <p className="head-note" >Please note: Holiday rate of ${item.holidaydaysinavg[0].dailyrate} per day in affect from {Constants.formatDate(item.holidaydaysinavg[0].startdate) } to {Constants.formatDate(item.holidaydaysinavg[0].enddate) }</p>
                                   } */}
                                  {item.holidaydaysinavg?.map((holiday, ix)=>{
                                       return (<p className="head-note" key={ix}>Please note: Holiday rate of ${holiday.dailyrate} per day in affect from {Constants.formatDate(holiday.startdate) } to {Constants.formatDate(holiday.enddate) }</p>)
                                    })} 
                                    {/* <div className="general-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.shortsecuritystatement)} />
                                    </div>
                                    <div className="complementary-info">
                                        <p dangerouslySetInnerHTML={createMarkup(item.transportation)} />
                                    </div> */}
                                    <div>
                                        {item.address1}
                                    </div>
                                </div>
                                <div className="price">
                                <div className="top-price">
                                    {/* <div className="ticketing">
                                        <h4>TYPE</h4>
                                        <p>{item.parkingtype}</p>
                                    </div> */}
                                    <div className="ticketing">
                                        <h4>AVG. RATE</h4>
                                        <p>${item.avgrate.toFixed(2)}</p>
                                    </div>
                                </div>
                                 
                                <div className="mid-price">
                                    <p>Sub-Total = ${item.subtotal.toFixed(2)}</p>
                                    <p>Service Fee = ${item.servicefee.toFixed(2)}</p>
                                    {item.accessfee>0 && <p>Access Fee = ${item.accessfee.toFixed(2)}</p>}
                                    <p>Sales Tax = ${item.tax.toFixed(2)}</p>
                                    {item.freedaysavings === 0 ? '' : <p>Free Day Savings = ${item.freedaysavings.toFixed(2)}</p> }
                                    <h3>Amount Total = ${item.onlinepay.toFixed(2)}</h3>
                                </div>
                             <div className="total-price">
                                    <h3>Amount Total = ${item.onlinepay.toFixed(2)}</h3>
                                    <p>Due at Lot = ${item.dueatlot.toFixed(2)}</p>
                                </div>

                              <div className="btn">
                                    <Link to={ `/reservation/checkout/${item.parkinglotid}` }>
                                        <button disabled={ item.booked === true || item.blocked === true}  className={item.booked === true || item.blocked === true ? 'cancel-btn' : 'reserve-btn'}>
                                            {item.booked === true || item.blocked === true ? 'UNAVAILABLE' : 'RESERVE'}
                                        </button>
                                    </Link>
                                </div> 

                               

                                </div>
                            </div>
                        )
                    })}
                </div> : ''}
                
            </div>}  
        </div>
    </div>
  )
}

export default LotLanding