import React, {useEffect,  useState, useContext} from 'react'
import '../../styles/User/directions.css'

import TopBar from '../../components/User/TopBar'
import * as Constants from '../../constants';
import ReactGA from 'react-ga';
import { useParams, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose, faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function Articles() {
    
    const [articles, setArticles] = useState([]);

    const loadArticle=()=>{

        var finalurl= `${Constants.apiUrl}/reservation/cms/articles`;

          fetch(finalurl, {
              method: 'GET',
              headers: {
                'Content-type': 'application/json',
              }
            })
            .then((resp) => resp.json())
            .then((data) =>  {
             
              if(data!=null){
                setArticles(data);
                  Constants.seo({title:'New Orleans Article', metaDescription:'New Orleans Article'});
                 
                  
              }
                  
              
            })
            .catch((error) => {
              console.log(error);
              
            })
          }

  return (
    <div className='articles'>
        <TopBar />
        <div className="body">
            <div className="heading">
                <h1> Articles</h1>
                <p>We have many articles </p>
            </div>
            <div className="articles-main">
                {
                    articles.map( article => {
                        return(
                            <div className="article" key={article.id}>
                                <h3>{article.title}</h3>
                                <ul>
                                <li>
                                                <p>{article.title}</p>
                                                <Link to={`/articles/${article.finalPath}`}><FontAwesomeIcon icon={faAngleRight} className='icon' /></Link>
                                            </li>
                                </ul>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}

export default Articles